import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import Api from '../app/api';
import { RootState, AppThunk, store } from '../app/store';

export interface HomeBook {
    seriesUrl: string,
    seriesName: string,
    name: string,
    image: string,
    shelfId: number,
    nbBooks: number,
    booksId: number[],
    isOneBookHasComment: boolean,
    isOneBookIsComingSoon: boolean,
    allEnglishDate: string | null,
    allCanadianDate: string | null,
    allFrenchDate: string | null,
    tags: number[],
    booksName: string, //separator: |||
    booksDescription: string, //separator: |||
    authorName: string

}

export interface SearchFilter {
    inTitle: boolean,
    inDescription: boolean,
    inAuthor: boolean,
}

export interface BooksState {
    booksByShelf: any,
    filterBooksByShelfId: any,
    searchTerm: string,
    searchFilter: SearchFilter,
    searchTags: number[]
}

const initialState: BooksState = {
    booksByShelf: {},
    searchTerm: '',
    searchFilter: {
        inTitle: true,
        inDescription: false,
        inAuthor: false
    },
    searchTags: [],
    filterBooksByShelfId: {}
};

export const booksSlice = createSlice({
    name: 'books',
    initialState,
    reducers: {
        setBooks: (state, action) => {
            state.booksByShelf = action.payload;
            state.filterBooksByShelfId = getFilterBooks(state);

        },
        setSearch: (state, action) => {
            state.searchTerm = action.payload.term;
            state.searchFilter.inTitle = action.payload.inTitle;
            state.searchFilter.inDescription = action.payload.inDescription;
            state.searchFilter.inAuthor = action.payload.inAuthor;
            state.filterBooksByShelfId = getFilterBooks(state);
        },
        setTagsFilter: (state, action) => {
            state.searchTags = action.payload;
            state.filterBooksByShelfId = getFilterBooks(state);
        }

    },
    // extraReducers: {
    //     [fetchBooks.fulfilled]: (state, action) => {
    //         state.booksByShelf = action.payload;
    //     }
    // }

});

const inSearch = (book: HomeBook, searchTerm: string) => {
    if (!searchTerm) {
        return true;
    }

    let search = searchTerm.toLowerCase();

    if (book.booksName.toLowerCase().match(search) !== null) {
        return true;
    }

    if (book.booksDescription.toLowerCase().match(search) !== null) {
        return true;
    }

    if (book.authorName.toLowerCase().match(search) !== null) {
        return true;
    }


    return false;
}

const inTagsFilter = (book: HomeBook, tagsFilter: number[]) => {
    let isInTagsFilter = tagsFilter.length === 0 ? true : false;
    tagsFilter.map((tagId: number) => {
        if (typeof book.tags !== 'undefined' && book.tags.indexOf(tagId) !== -1) {
            isInTagsFilter = true;
        }
    });
    return isInTagsFilter;
}

const getFilterBooks = (state: BooksState) => {
    let filtersBooksByShelf: any = {};
    for (let shelfId in state.booksByShelf) {
        filtersBooksByShelf[shelfId] = [];
        let books = state.booksByShelf[shelfId];
        books.map((book: HomeBook) => {
            if (inSearch(book, state.searchTerm) && inTagsFilter(book, state.searchTags)) {
                filtersBooksByShelf[shelfId].push(book);
            }
        });

    }
    return filtersBooksByShelf;
}

export const { setBooks, setSearch, setTagsFilter } = booksSlice.actions;

export const selectBooksForShelf = (state: RootState, shelfId: any) => typeof state.books.booksByShelf[shelfId] !== 'undefined' ? state.books.booksByShelf[shelfId] : [];
export const selectFilterBooksForShelf = (state: RootState, shelfId: any) => typeof state.books.filterBooksByShelfId[shelfId] !== 'undefined' ? state.books.filterBooksByShelfId[shelfId] : [];
export const getInternSearchTerm = (state: RootState) => state.books.searchTerm;
export const getSearchFilter = (state: RootState) => state.books.searchFilter;
export const getSearchTags = (state: RootState) => state.books.searchTags;

export default booksSlice.reducer;
