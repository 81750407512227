import React from 'react';
import { useSelector } from 'react-redux';
import { selectErrorMessage } from '../../slices/userSlice';

import './errorMessage.css';

export function ErrorMessage() {

    const message: string | null = useSelector(selectErrorMessage);
    if (message === null) {
        return <></>;
    }

    return <div className="errorMessageModal">{message}</div>
}
