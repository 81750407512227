import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './explanation.css';

export default function Explanation() {
    const [indexStep, setIndexStep] = useState(0);
    const navigate = useNavigate();

    const Home = () => {
        return (
            <div className="stepExplanation">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="home.png" />
                    <div className="bottomApp"></div>
                </div>
                <div>
                    <h2>La page d'accueil</h2>
                    Retrouvez tous vos livres regroupés dans vos étagères.
                </div>
            </div>
        )
    }

    const BookModal = () => {
        return (
            <div className="stepExplanation">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="home.png" />
                    <div className="bottomApp"></div>
                    <div className="arrowTarget" style={{ top: '3.5rem', left: '-4.1rem', transform: 'rotate(12deg)' }}><i className="bi bi-arrow-right"></i></div>
                </div>
                <div>
                    <h2>La page d'accueil</h2>
                    Au clic d'un livre, vous avez accès à sa description, sa date de sortie et son auteur. Mais également toutes les actions possibles sur un livre comme le changement d'étagère.
                </div>
            </div>
        )
    }

    const Search = () => {
        return (
            <div className="stepExplanation searchStep">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="home.png" />
                    <div className="bottomApp"></div>
                    <div className="arrowTarget" style={{ bottom: '1.2rem', right: '2rem', transform: 'rotate(32deg)' }}><i className="bi bi-arrow-right"></i></div>
                </div>
                <div>
                    <h2>La recherche</h2>
                    Mais avant tout, il faut ajouter des livres ! Pour ça, recherchez vos livres via le bouton de recherche en bas à droite de votre téléphone.
                    <br /><br />
                    Les livres et auteurs que vous trouverez proviennent du site internet <a href="https://booknode.com/" target="_blank" className="link">booknode.com</a>.
                </div>
            </div>
        )
    }

    const Book = () => {
        return (
            <div className="stepExplanation">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="book.png" />
                    <div className="bottomApp"></div>
                </div>
                <div>
                    <h2>La fiche internet</h2>
                    Une fois le livre trouvé, vous tomberez sur la fiche internet qui affiche toutes les informations trouvées sur le livre (toujours via <strong>booknode.com</strong>).
                </div>
            </div>
        )
    }

    const AddBook = () => {
        return (
            <div className="stepExplanation">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="book.png" />
                    <div className="bottomApp"></div>
                    <div className="arrowTarget" style={{ top: '10.7rem', right: '2.3rem', transform: 'rotate(-43deg)' }}><i className="bi bi-arrow-right"></i></div>
                </div>
                <div>
                    <h2>La fiche internet</h2>
                    Via cette fiche vous pouvez ajouter le livre dans votre étagère via le bouton "ajouter".
                </div>
            </div>
        )
    }

    const AddSeries = () => {
        return (
            <div className="stepExplanation">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="book_series.png" />
                    <div className="bottomApp"></div>
                    <div className="arrowTarget" style={{ bottom: '5.4rem', left: '2.4rem', transform: 'rotate(129deg)' }}><i className="bi bi-arrow-right"></i></div>
                </div>
                <div>
                    <h2>La fiche internet</h2>
                    Si vous voulez ajouter toute la série, vous avez également un bouton dans la section série !
                </div>
            </div>
        )
    }

    const LongPressAddBook = () => {
        return (
            <div className="stepExplanation">
                <div className="imageExplanation">
                    <div className="topApp"></div>
                    <img src="phone.png" />
                    <img src="book_series.png" />
                    <div className="bottomApp"></div>
                    <div className="arrowTarget" style={{ bottom: '4.8rem', left: '2.4rem', transform: 'rotate(72deg)' }}><i className="bi bi-arrow-right"></i></div>
                </div>
                <div>
                    <h2>La fiche internet</h2>
                    Et la dernière manière d'ajouter un livre est de faire un appui prolongé sur les livres.
                </div>
            </div>
        )
    }

    const Subscribe = () => {
        return (
            <div className="stepExplanation">
                <div className="iconExplanation"><i className="bi bi-bell-fill"></i></div>
                <div>
                    <h2>Les abonnements</h2>
                    En étant sur la fiche internet d'un livre ou d'un auteur, vous avez la possibilité de suivre soit l'auteur soit une série.
                    <br /><br />En les suivant, vous recevrez un notification dans l'application dès qu'un livre a été ajouté chez booknode !
                </div>
            </div>
        )
    }

    const Param = () => {
        return (
            <div className="stepExplanation">
                <div className="iconExplanation"><i className="bi bi-gear"></i></div>
                <div>
                    <h2>Les paramètres</h2>
                    Et pour finir, allez faire un tour dans les paramètres.
                    <br /><br />
                    Vous pourrez modifier les étagères et les abonnements de vos auteurs et séries.
                    <br /><br />Sans oublier votre historique, la possibilité de changer votre mot de passe, passer l'application en mode sombre ou encore créer des tags pour des filtres personnalisés.
                </div>
            </div>
        )
    }

    const steps = [
        <Home />,
        <BookModal />,
        <Search />,
        <Book />,
        <AddBook />,
        <AddSeries />,
        <LongPressAddBook />,
        <Subscribe />,
        <Param />
    ]

    return (
        <div className="explanation">
            {steps[indexStep]}
            <footer>
                {indexStep !== 0 && <button className="goBack" onClick={() => setIndexStep(indexStep === 0 ? 0 : indexStep - 1)}>Revenir en arrière</button>}
                <button onClick={() => indexStep === steps.length - 1 ? navigate('/books') : setIndexStep(indexStep + 1)}>{indexStep === steps.length - 1 ? 'Terminer' : 'Continuer'}</button>
            </footer>
        </div>

    )
}
