import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteBookStat, fetchStats, updateDateStatsForBook } from '../../app/fetchApi';
import { Link } from 'react-router-dom';
import './stats.css';
import { BookItem } from '../../features/BookItem/BookItem';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import { DateFormat } from '../../utils/Utils';

export interface StatsReponse {
    yearSelected: 'current' | string
    yearsWithStats: string[]
    stats: MonthStats[]
}

export interface MonthStats {
    month: string,
    books: BookMonth[]
}

export interface BookMonth {
    id: number,
    name: string,
    date: string,
    image: string
}

export function Stats() {
    const now = new DateFormat(new Date()).format('Y-m-d');

    const dispatch = useDispatch();
    const [selectedYear, setSelectedYear] = useState('current');
    const [yearsWithStats, setYearsWithStats] = useState([] as string[]);
    const [booksMonths, setBooksMonths] = useState([] as MonthStats[]);
    const [selectedBook, setSelectedBook] = useState<null | BookMonth>(null);

    useEffect(() => {
        loadStats();
    }, [selectedYear])

    const deleteConfirmStat = (selectedBook: BookMonth) => {
        dispatch(deleteBookStat(selectedBook.id, () => {
            onCloseModal();
        }));

    }

    const loadStats = () => {
        dispatch(fetchStats(selectedYear, (response: StatsReponse) => {
            setYearsWithStats(response.yearsWithStats);
            setBooksMonths(response.stats);
        }))
    }

    const onChangeDate = (selectedBook: BookMonth, date: string) => {
        dispatch(updateDateStatsForBook(date, selectedBook.id));
    }

    const onCloseModal = () => {
        setSelectedBook(null);
        loadStats();
    }


    return (
        <div className="statsScreen">
            <header>
                <div>
                    <Link to="/books"> <i className="bi bi-arrow-left"></i></Link>
                    <h1>Statistiques</h1>
                </div>
            </header>
            <main>
                <ul className="menu-stats horizontalScroll">
                    <li className={selectedYear === 'current' ? 'selected' : ''} onClick={() => setSelectedYear('current')} ><a>ANNÉE COURANTE</a></li>
                    {yearsWithStats.map(year => <li key={year}><a onClick={() => setSelectedYear(year)}>{year}</a></li>)}
                </ul>
                <div className="yearBooks scroll">
                    {booksMonths.map(bookMonth =>
                        <div key={bookMonth.month} className="month">
                            <MonthName monthNumber={bookMonth.month} />
                            <div className="booksList">
                                {bookMonth.books.length === 0 && <div className="notFoundMessage">Aucun livre lu</div>}
                                {bookMonth.books.map((book: BookMonth) =>
                                    <BookItem
                                        key={book.id}
                                        name={book.name}
                                        image={book.image}
                                        nbBooks={1}
                                        width="100%"
                                        onClick={() => setSelectedBook(book)}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {selectedBook !== null &&
                    <BottomModal
                        title={"Modifier les statistiques"}
                        subTitle={selectedBook.name}
                        onClose={() => onCloseModal()}
                    >
                        <ul className="moreOptions-list">
                            <li>
                                <i className="bi bi-pencil"></i>
                                <div>
                                    <div><strong>Changer la date de lecture</strong></div>
                                    <input type="date" max={now} defaultValue={selectedBook.date} onChange={(e) => onChangeDate(selectedBook, e.currentTarget.value)} />
                                </div>
                            </li>
                            <li onClick={() => deleteConfirmStat(selectedBook)}>
                                <i className="bi bi-trash"></i>
                                <div>
                                    <div><strong>Je n'ai pas lu ce livre</strong></div>
                                    <span>Supprimer cette statistique</span>
                                </div>
                            </li>
                        </ul>
                    </BottomModal>
                }
            </main>
        </div>
    );
}

interface MonthNameProps {
    monthNumber: string
}

const MonthName = (props: MonthNameProps) => {
    const now = new Date();
    const date = new Date(now.getFullYear() + '-' + props.monthNumber + '-01');
    const monthName = date.toLocaleString('default', { month: 'long' });
    return (
        <strong>{monthName.toUpperCase()}</strong>
    )
}
