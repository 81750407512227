import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

const COLORS = [
    '#feb545',
    '#7424b9',
    '#24b93f',
    '#e86727',
    '#f9dd01',
    '#e82727',
    '#2495b9',
    '#b92475',
    '#24b984',
    '#1151e8',
    '#15e0cf'
];

export interface Tag {
    id: number,
    name: string,
    color: string
}


export interface TagsState {
    tags: Tag[]
}

const initialState: TagsState = {
    tags: []
};




export const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setTags: (state, action) => {
            const tags = action.payload;
            tags.map((tag: Tag, index: number) => tag.color = COLORS[index]);
            state.tags = tags;
        },
        setTag: (state, action) => {
            const tagData = action.payload;
            const tags = state.tags.slice();
            let tagUpdated = tags.find(tag => tag.id === tagData.id);
            if (tagUpdated) {
                tagUpdated.name = tagData.name;
            }
            state.tags = tags;
        },


    }

});

export const { setTags } = tagsSlice.actions;
export const { setTag } = tagsSlice.actions;

export const selectTags = (state: RootState) => state.tags.tags;
export const selectTag = (state: RootState, tagId: number) => state.tags.tags.find(tag => tag.id === tagId);

export default tagsSlice.reducer;
