import React, { useEffect, useState } from 'react';
import './scan.css';
import { BOOKNODE_URL, checkSession, fetchSearch, removeSystemMessage, sendLoadingMessage } from '../../app/fetchApi';
import { useDispatch } from 'react-redux';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import { Book } from '../Book/Book';
import { Link } from 'react-router-dom';
import { Html5Qrcode } from "html5-qrcode"

let html5QrCode: Html5Qrcode;

export function Scan() {
    const dispatch = useDispatch();
    const [books, setBooks] = useState([]);
    const [bestMatch, setBestMatch] = useState([]);
    const [bottomModalType, setBottomModalType] = useState('');
    const [errorCodeBar, setErrorCodeBar] = useState('');
    const [codeBar, setCodeBar] = useState('');
    const [bookOverview, setBookOverview] = useState({ url: '', website: '' });
    const [isScanStart, setIsScanStart] = useState(false);
    const [loadingScanner, setLoadingScanner] = useState(false);
    const [awaitGoogleResponse, setAwaitGoogleResponse] = useState('');
    useEffect(() => {
        checkSession();
        scannerInit();

        return () => {
            scannerStop();
        }
    }, [])

    useEffect(() => {
        if (awaitGoogleResponse) {
            getBookDataByISBN(awaitGoogleResponse);
        }
    }, [awaitGoogleResponse])

    let lastCode: any = null;

    const scannerInit = () => {
        html5QrCode = new Html5Qrcode("reader");
        const config: any = { fps: 10, qrbox: { width: 200, height: 100 } };

        setAwaitGoogleResponse('');
        setLoadingScanner(true);
        setIsScanStart(true);

        html5QrCode.start(
            { facingMode: { exact: "environment" } },
            config,
            (decodedText, decodedResult) => {
                console.log(awaitGoogleResponse, decodedText, decodedResult);
                if (awaitGoogleResponse) {
                    return;
                }

                if (decodedResult.result.format?.formatName !== 'EAN_13') {
                    return;
                }

                setAwaitGoogleResponse(decodedText);
            },
            () => { }
        ).then(() => {
            setLoadingScanner(false);
        }).catch((err) => {
            setIsScanStart(false);
            console.log('catch err', err);
        });
    }


    const scannerStop = () => {
        if (html5QrCode && html5QrCode.isScanning) {
            html5QrCode.stop().then(() => {
                setIsScanStart(false);
                setAwaitGoogleResponse('');
            }).catch(() => { })
        }
    }

    const getBookDataByISBN = (code: string, callbackError?: Function) => {
        sendLoadingMessage(dispatch);
        fetch(`https://www.googleapis.com/books/v1/volumes?q=+isbn:${code}`)
            .then(response => response.json())
            .then(response => {
                const bookInfo = response && response.items && response.items[0] && response.items[0].volumeInfo ? response.items[0].volumeInfo : {};
                searchInBooknode(bookInfo.title, bookInfo.authors);
                removeSystemMessage(dispatch);
                if (typeof callbackError === 'function' && !bookInfo.title) {
                    callbackError('Erreur code barre : aucun livre trouvé');

                }
            })
            .catch(err => {
                console.log('error', err);
                removeSystemMessage(dispatch);
                setAwaitGoogleResponse('');
            })
    }

    const searchInBooknode = (title: string, authors: string[]) => {
        if (!title) {
            setAwaitGoogleResponse('');
            return;
        }

        scannerStop();
        fetchSearch(title, 'booknode', (result: any) => {
            let founded: any = [];
            let books = result.books;
            result.books.map((book: any) => {

                const bookName = book.name.toLowerCase();
                title = title.toLowerCase();
                if (bookName.match(title) === null && title.match(bookName) === null) {
                    return;
                }

                book.authors.map((author: any) => {
                    const firstName = author.obj.prenom.toLowerCase();
                    const lastName = author.obj.nom.toLowerCase();
                    authors.map(authorName => {
                        const authorNameLowerCase = authorName.toLowerCase();
                        if (authorNameLowerCase.match(lastName)) {
                            books = books.filter((filterBook: any) => filterBook.id !== book.id);
                            founded.push({ id: book.id, book, firstnameMatch: authorNameLowerCase.match(firstName) === null ? false : true });
                        }
                    })

                })
            })


            if (founded.length === 1) {
                changeBook(founded[0].book);
            } else {
                setBooks(books);
                setBestMatch(founded);
                setBottomModalType('SEARCH')
            }


        }, dispatch);
    }

    const changeBook = (book: any) => {
        const url = book.href.replace(BOOKNODE_URL, '');
        setBookOverview({ url: url, website: 'booknode' });
        setBottomModalType('BOOK');
    }

    const foundCodeBar = (e: any) => {
        const value = e.currentTarget.value.trim();
        if (errorCodeBar) {
            setErrorCodeBar('');
        }
        setAwaitGoogleResponse('');
        setCodeBar(value);

        //ISBN-13
        if (isIsbn13(value)) {
            if (value.length !== 13) {
                return;
            }

            getBookDataByISBN(value, (error: string) => setErrorCodeBar(error));
            //ISBN-10
        } else if (value.length === 10) {
            getBookDataByISBN(value, (error: string) => setErrorCodeBar(error));
        }
    }

    const isIsbn13 = (code: string) => {
        return code.startsWith('978') || code.startsWith('979') ? true : false;
    }

    const classCodeBarIndication = () => {
        const length = isIsbn13(codeBar) ? 13 : 10;
        return codeBar.length > length ? 'error' : codeBar.length === length ? 'success' : '';
    }

    return (
        <div className="scanContainer">
            <header>
                <div>
                    <Link to={"/books"}><i className="bi bi-arrow-left"></i></Link>
                    <h1>Recherche par code barre</h1>
                </div>
            </header>
            <main>
                <p className="subTitle"><strong>Scanner le code barre : </strong></p>
                <div className="viewportScan">
                    <div className="startVideoContainer" style={{ display: !isScanStart || loadingScanner ? 'block' : 'none' }}>
                        {!isScanStart
                            ? <button onClick={() => scannerInit()}>Démarrer la caméra</button>
                            : <span>Chargement...</span>
                        }
                    </div>
                    <div id="reader"></div>
                </div>
                <p className="subTitle"><strong>Ou taper le code barre : </strong></p>
                <p className="errorMsg">{errorCodeBar}</p>
                <div>
                    <div className="inputContainer">
                        <input type="text" value={codeBar} onChange={(e) => foundCodeBar(e)} />
                        {codeBar.length >= 3 && <span className={classCodeBarIndication()} >{codeBar.length}/{isIsbn13(codeBar) ? '13' : '10'}</span>}
                    </div>
                </div>

                {bottomModalType && <BottomModal
                    title={bottomModalType === 'BOOK' ?
                        <>
                            {(books.length > 0 || bestMatch.length > 0) && <button
                                className="goBackOverview"
                                onClick={() => {
                                    setBottomModalType('SEARCH');
                                    setBookOverview({ url: '', website: '' });
                                }}
                            >
                                <i className="bi bi-arrow-left"></i>
                            </button>}
                            Aperçu
                        </> : 'Résultats'}
                    onClose={() => {
                        setBooks([]);
                        setBestMatch([]);
                        setBottomModalType('');
                    }}
                >
                    {bottomModalType === 'BOOK' && <Book simpleForm={true} bookUrl={bookOverview.url} website={bookOverview.website} />}

                    {bottomModalType === 'SEARCH' &&
                        <div className="searchList">
                            {bestMatch.map((info: any) => <BookSearchItem key={info.id} book={info.book} isBestMatch={true} changeBook={changeBook} />)}
                            {books.map((book: any) => <BookSearchItem key={book.id} book={book} isBestMatch={false} changeBook={changeBook} />)}

                        </div>
                    }


                </BottomModal>}


            </main>
        </div>
    )
}

function BookSearchItem(props: any) {
    const book = props.book;
    const changeBook = props.changeBook;

    return (
        <div className="searchBook">
            <div onClick={() => changeBook(book)} className="imageSearchBook" style={{ backgroundImage: 'url(' + book.cover_small + ')' }}></div>
            <div>
                {props.isBestMatch && <span className="bestResult" >Meilleur résultat</span>}
                <strong onClick={() => changeBook(book)}>{book.name}</strong>
                {book.authors && typeof book.authors[0] !== 'undefined' && book.authors[0].nom}
            </div>
        </div>
    )
}
