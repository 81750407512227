import React from 'react';
import './searchBooksSkeleton.css';

export default function SearchBooksSkeleton() {
    return (
        <div className="searchBooksSkeleton">
            <div className="title">
                <div></div>
                <div></div>
            </div>
            <div className="items">
                <div>
                    <div className="image"></div>
                    <div className="content">
                        <div className="title"></div>
                        <div className="link"></div>
                    </div>
                </div>
                <div>
                    <div className="image"></div>
                    <div className="content">
                        <div className="title"></div>
                        <div className="link"></div>
                    </div>
                </div>
                <div>
                    <div className="image"></div>
                    <div className="content">
                        <div className="title"></div>
                        <div className="link"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
