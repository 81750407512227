import React, { MouseEventHandler, useEffect, useState } from 'react';
import './bookItem.css';
import { useLongPress } from 'use-long-press';

export interface BookItemProps {
    name: string,
    seriesName?: string,
    image: string,
    nbBooks: number,
    hasComment?: boolean,
    hasTags?: boolean,
    shelfName?: string,
    isComingSoon?: boolean,
    width?: string,
    height?: string,
    onClick: MouseEventHandler | undefined
    onLongPress?: Function
}

export const BookItem = React.memo((props: BookItemProps) => {

    const getSubTitle = () => {
        return props.nbBooks > 1 ? props.nbBooks + ' TOMES REGROUPÉS' : props.name;
    }

    const longPressEvent = useLongPress(() => {
        if (typeof props.onLongPress === 'function') {
            props.onLongPress();
        }
    }, {
        cancelOnMovement: true
    });

    return (
        <button {...longPressEvent()} onClick={props.onClick} className="bookItem" style={{ width: !props.width ? 'calc((100% / 3) - 0.4rem * 2)' : props.width }} >
            <div className={`imageContainer aspectRatio`} style={{ backgroundImage: 'url(' + (props.image ? props.image : 'https://cdn.pixabay.com/photo/2017/05/13/09/04/question-2309040_1280.jpg') + ')', height: props.height ? props.height : 'auto' }}>
                <div>
                    {props.hasComment && <i className="bi bi-justify-left hasComment"></i>}
                    {props.hasTags && <i className="bi bi-tags-fill hasTags"></i>}
                    {props.isComingSoon && <span className="next">Prochainement</span>}
                    {props.shelfName && <span className="shelf"><i className="bi bi-book"></i> <span>{props.shelfName}</span></span>}
                </div>
            </div>
            <div><strong>{props.seriesName}</strong></div>
            <div className="subTitle">{getSubTitle()}</div>
        </button >
    )
}, (prev, next) => prev.name === next.name
    && prev.seriesName === next.seriesName
    && prev.image === next.image
    && prev.nbBooks === next.nbBooks
    && prev.shelfName === next.shelfName
    && prev.hasComment === next.hasComment
    && prev.hasTags === next.hasTags
    && prev.isComingSoon === next.isComingSoon
);
