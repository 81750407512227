import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchAuthor, fetchAuthorFollow, fetchFirstBookUrlOnSeries, fetchUnFollow } from '../../app/fetchApi';
import { BookCommentModal } from '../../features/BookCommentModal/BookCommentModal';
import { BookItem } from '../../features/BookItem/BookItem';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import { ChangeShef } from '../../features/ChangeShelf/ChangeShelf';
import { TagsModal } from '../../features/TagsModal/TagsModal';
import { selectBooksForShelf } from '../../slices/booksSlice';
import { DateFormat } from '../../utils/Utils';
import './author.css';
import { AuthorSkeleton } from './AuthorSkeleton';

interface Author {
    books: BookAuthor[],
    name: string,
    image: string,
    themes: string[],
    website: 'booknode',
    url: string,
    biography: string,
    hasSubscription: boolean
}

interface BookAuthor {
    url: string,
    name: string,
    image: string,
    isSeries: boolean,
    website: 'booknode',
    database: null | BookDatabase
}


// pareil que interface BookDetail
interface BookDatabase {
    id: number,
    url: string,
    website: 'booknode',
    authorUrl: string,
    authorname: string,
    comment: string | null,
    image: string,
    name: string,
    publishDate: PublishDate,
    seriesName: string,
    seriesUrl: string,
    shelfId: number,
    shelfName: string,
    synopsis: string,
    tags: number[],

}

interface PublishDate {
    CANADIAN: string,
    FRENCH: string,
    ENGLISH: string
}

export function Author() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const url = params.getAll('url');
    const website = params.getAll('website');
    const [authorUrl, setAuthorUrl] = useState<null | string>(typeof url !== 'undefined' || url[0] ? url[0] : null);
    const [authorWebsite, setAuthorWebsite] = useState<null | string>(typeof website !== 'undefined' || website[0] ? website[0] : null);
    const [author, setAuthor] = useState<null | Author>(null);

    useEffect(() => {
        getAuthor();
    }, [authorUrl])


    const getAuthor = () => {
        if (authorUrl === null || authorWebsite === null) {
            return;
        }

        dispatch(fetchAuthor(authorUrl, authorWebsite, (author: Author) => {
            setAuthor(author);
        }));
    }

    const onClickBook = (book: BookAuthor) => {
        if (!book.isSeries) {
            navigate('/book?url=' + book.url + '&website=' + book.website);
            return;
        }

        dispatch(fetchFirstBookUrlOnSeries(book.url, book.website, (bookUrl: string) => {
            navigate('/book?url=' + bookUrl + '&website=' + book.website);
        }));
    }

    if (author === null) {
        return <AuthorSkeleton />;
    }

    return (
        <div className="authorScreen">
            <NavButtons />
            <div className="imageContainer" style={{ backgroundImage: 'url(' + author.image + ')' }}></div>

            <AuthorActions author={author} setAuthor={(author: Author) => setAuthor(author)} />

            <div className="body">
                <h1>{author.name}</h1>

                <div dangerouslySetInnerHTML={{ __html: author.biography }}></div>

                <div>
                    <strong>Les thèmes</strong>
                    <br />
                    <div className="authorThemes">{author.themes.map((theme, index) => theme + (index + 1 !== author.themes.length ? ' - ' : ''))}</div>
                </div>

                <div>
                    <strong>Les livres</strong>
                    <br />
                    <div className="authorBooks">
                        {author.books.map(book =>
                            <BookItem
                                key={book.url}
                                name={book.isSeries ? 'Séries ' + book.name : book.name}
                                image={book.image}
                                nbBooks={1}
                                onClick={() => onClickBook(book)}
                                shelfName={!book.isSeries && book.database ? book.database.shelfName : ''}
                                width="100%"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
}

function NavButtons() {
    const navigate = useNavigate();

    return (
        <div className="navButtons">
            <button onClick={() => navigate('/books')}><i className="bi bi-house-fill"></i></button>
            <button onClick={() => window.history.back()}><i className="bi bi-arrow-left"></i></button>
        </div>
    )
}

interface AuthorActionsProps {
    author: Author,
    setAuthor: Function
}
function AuthorActions(props: AuthorActionsProps) {
    const [showMore, setShowMore] = useState(false);
    const dispatch = useDispatch();

    const followOrUnFollow = () => {
        if (props.author.hasSubscription) {
            dispatch(fetchUnFollow(props.author.url, 'author', () => {
                props.setAuthor({ ...props.author, hasSubscription: false } as Author);
            }));
            return;
        }

        const booksUrl: string[] = [];
        props.author.books.map(book => {
            booksUrl.push(book.url);
        });

        dispatch(fetchAuthorFollow(props.author.url, props.author.website, props.author.name, booksUrl, () => {
            props.setAuthor({ ...props.author, hasSubscription: true } as Author);
        }));
    };

    return (
        <>
            <div className="authorActions">
                <button onClick={() => setShowMore(true)}>
                    <i className="bi bi-three-dots-vertical"></i><span>Options</span>
                </button>
            </div>

            {showMore &&
                <BottomModal
                    title="Plus d'options"
                    onClose={() => setShowMore(false)}
                >
                    <ul className="moreOptions-list">
                        <li onClick={() => followOrUnFollow()}>
                            <i className="bi bi-link"></i>
                            <div>
                                {!props.author.hasSubscription ?
                                    <>
                                        <strong>Suivre la série</strong>
                                        <span>Tu seras notifier dès qu'un nouveau tome sera ajouté.</span>
                                    </>
                                    : <strong>Ne plus suivre la série</strong>
                                }
                            </div>
                        </li>
                    </ul>

                </BottomModal>
            }

        </>
    )
}
