import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchConnection } from '../../app/fetchApi';
import './connection.css';

export function Connection() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');


    useEffect(() => {
        if (window.localStorage.getItem('token') && window.localStorage.getItem('refreshToken')) {
            navigate('/books');
            return;
        }

    }, []);

    const connect = (e: any) => {
        e.preventDefault();
        setErrorMsg('');
        dispatch(fetchConnection(username, password, (result: any) => {
            if (result.isFirstConnection) {
                navigate('/hello');
                return;
            }
            navigate('/books');
        }, (err: string) => setErrorMsg(err)));
    }

    return (
        <div className="connectionContainer">
            <h1>Connexion</h1>
            {errorMsg && <div className="errorMsg">{errorMsg}</div>}
            <form onSubmit={(e) => connect(e)}>
                <input type="text" value={username} placeholder="Identifiant" onChange={(e) => setUsername(e.currentTarget.value)} />
                <input type="password" value={password} placeholder="Mot de passe" onChange={(e) => setPassword(e.currentTarget.value)} />

                <button type="submit">Se connecter</button>
            </form>
        </div>
    )
}
