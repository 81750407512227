import React from 'react';
import './authorSkeleton.css';

export function AuthorSkeleton() {

    return (
        <div className="authorSkeleton">
            <div className="imageContainer"></div>
            <div className="body">
                <h1></h1>

                <div className="descriptionSection">
                    <div></div>
                </div>
            </div>
        </div>
    );
}
