export class DateFormat {
    constructor(dateToFormat) {
        this.dateObject = dateToFormat instanceof Date ? dateToFormat : new Date(dateToFormat);
    
        this.year =  this.dateObject.getFullYear();
        var monthInInteger = this.dateObject.getMonth()+1;
        this.month =  monthInInteger.toString().length > 1 ? monthInInteger.toString() : '0' + monthInInteger.toString();
        this.day =  this.dateObject.getDate().toString().length > 1 ? this.dateObject.getDate() : '0'+this.dateObject.getDate().toString();
        this.hours =  this.dateObject.getHours().toString().length > 1 ? this.dateObject.getHours() : '0' + this.dateObject.getHours();
        this.minutes =  this.dateObject.getMinutes().toString().length > 1 ? this.dateObject.getMinutes() : '0' + this.dateObject.getMinutes();
    
    }
   
    format = (stringFormat) => {
        switch (stringFormat) {
            case 'd/m/Y':
                return this.day+'/'+this.month+'/'+this.year;
            case 'Y-m-d':
                return this.year+'-'+this.month+'-'+this.day;
            case 'H:m':
                return this.hours+':'+this.minutes;
            case 'Y-m-d H:m':
                return this.year+'-'+this.month+'-'+this.day+' '+this.hours+':'+this.minutes;
            case 'd/m/Y H:m':
                return this.day+'/'+this.month+'/'+this.year+' '+this.hours+':'+this.minutes;
        }
    };
    
    getDateObject = function() {
        return this.dateObject;
    };

    addMinutes = (nbMinutes) => {
        this.dateObject.setMinutes(this.minutes+nbMinutes);
        this.year =  this.dateObject.getFullYear();
        var monthInInteger = this.dateObject.getMonth()+1;
        this.month =  monthInInteger.toString().length > 1 ? monthInInteger.toString() : '0' + monthInInteger.toString();
        this.day =  this.dateObject.getDate().toString().length > 1 ? this.dateObject.getDate() : '0'+this.dateObject.getDate().toString();
        this.hours =  this.dateObject.getHours().toString().length > 1 ? this.dateObject.getHours() : '0' + this.dateObject.getHours();
        this.minutes =  this.dateObject.getMinutes().toString().length > 1 ? this.dateObject.getMinutes() : '0' + this.dateObject.getMinutes();
    };

    removeDays = (nbDays) => {
        this.dateObject.setDate(this.dateObject.getDate() - nbDays);
        this.year =  this.dateObject.getFullYear();
        var monthInInteger = this.dateObject.getMonth()+1;
        this.month =  monthInInteger.toString().length > 1 ? monthInInteger.toString() : '0' + monthInInteger.toString();
        this.day =  this.dateObject.getDate().toString().length > 1 ? this.dateObject.getDate() : '0'+this.dateObject.getDate().toString();
        this.hours =  this.dateObject.getHours().toString().length > 1 ? this.dateObject.getHours() : '0' + this.dateObject.getHours();
        this.minutes =  this.dateObject.getMinutes().toString().length > 1 ? this.dateObject.getMinutes() : '0' + this.dateObject.getMinutes();
    };

    addDays = (nbDays) => {
        this.dateObject.setDate(this.dateObject.getDate() + nbDays);
        this.year =  this.dateObject.getFullYear();
        var monthInInteger = this.dateObject.getMonth()+1;
        this.month =  monthInInteger.toString().length > 1 ? monthInInteger.toString() : '0' + monthInInteger.toString();
        this.day =  this.dateObject.getDate().toString().length > 1 ? this.dateObject.getDate() : '0'+this.dateObject.getDate().toString();
        this.hours =  this.dateObject.getHours().toString().length > 1 ? this.dateObject.getHours() : '0' + this.dateObject.getHours();
        this.minutes =  this.dateObject.getMinutes().toString().length > 1 ? this.dateObject.getMinutes() : '0' + this.dateObject.getMinutes();
    };

}


export const ASC_DIRECTION = 'ASC';
export const DESC_DIRECTION = 'DESC';

export const sortBy = (arrayToSort, key, sortDirection) => {
    let newArray = arrayToSort.sort(function(firstItem, secondItem) {
        return firstItem[key] - secondItem[key];
    });

    if (sortDirection === DESC_DIRECTION) {
        return newArray.reverse();
    }

    return newArray;
}


export const objectSerialize = (obj, prefix) => {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
            objectSerialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
};

export const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9);
}