import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchNotificationRemove, fetchNotifications, setNotificationOld } from '../../app/fetchApi';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import './notification.css';

interface Notification {
    id: number,
    title: string,
    description: string,
    link: string,
    linkType: 'FORM_BOOK' | 'APP_UPDATE',
    isNew: boolean
}

export function Notification() {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [descriptionToShow, setDescriptionToShow] = useState('');

    useEffect(() => {
        dispatch(fetchNotifications((notifications: Notification[]) => setNotifications(notifications)));
    }, [dispatch])

    useEffect(() => {
        window.onpopstate = () => {
            setOldNotifications();
        }

        return function cleanup() {
            window.onpopstate = () => { };

        }
    }, [])

    const remove = (id: number | 'ALL') => {

        dispatch(fetchNotificationRemove(id, () => {
            let newNotifications: Notification[] = [];

            if (id !== 'ALL') {
                newNotifications = notifications.filter((notif) => notif.id !== id);
            }

            setNotifications(newNotifications);
        }))
    };

    const onClickNotif = (notif: Notification) => {
        setNotificationOld([notif.id]);
        if (notif.linkType === 'APP_UPDATE') {
            setDescriptionToShow(notif.description);
            return;
        }
        navigate(`/book?url=${notif.link}&website=booknode`);
    };

    const goBack = () => {
        setOldNotifications();
        navigate("/books");
    }

    const setOldNotifications = () => {
        const newNotifs: number[] = [];
        notifications.map(notif => {
            if (notif.isNew) {
                newNotifs.push(notif.id);
            }
        })

        if (newNotifs.length > 0) {
            setNotificationOld(newNotifs);
        }
    }

    return (
        <div className="notifScreen">
            <header>
                <div>
                    <a onClick={() => goBack()}><i className="bi bi-arrow-left"></i></a>
                    <h1>Notifications</h1>
                </div>
            </header>
            <main>
                <div className="empty">{notifications.length > 0 && <a onClick={() => remove('ALL')}>tout vider</a>}</div>
                {notifications.length === 0 && <div className="notFoundMessage">Aucune notification à afficher.</div>}
                <ul>
                    {notifications.map(notif =>
                        <li key={notif.id}>
                            {notif.linkType === 'FORM_BOOK' ? <i className="bi bi-book"></i> : <i className="bi bi-bell-fill"></i>}
                            <a onClick={() => onClickNotif(notif)}>
                                <strong>{notif.title}</strong> {notif.isNew && <span className="isNew">Nouveau</span>}
                                <div>{notif.linkType === 'APP_UPDATE' ? <u>Voir les détails</u> : notif.description}</div>
                            </a>
                            <button className="close" onClick={() => remove(notif.id)}><i className="bi bi-x-lg"></i></button>
                        </li>
                    )}
                </ul>
                {descriptionToShow &&
                    <BottomModal
                        title='Contenu de la mise à jour'
                        onClose={() => setDescriptionToShow('')}
                    >
                        <div className="bottomModalAppUpdate" dangerouslySetInnerHTML={{ __html: descriptionToShow }} />
                    </BottomModal>
                }
            </main>
        </div>
    )
}
