import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchChangePassword, fetchCreateNotif, fetchCreateUser, fetchLogout, fetchUser } from '../../app/fetchApi';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import { selectNbBooksByShef, selectTheme, selectUser, setNbBooksByShef, setTheme } from '../../slices/userSlice';

import './param.css';

export function Param() {
    let location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMenuParam, setIsMenuParam] = useState(true);
    const [title, setTitle] = useState('');
    const [showBooksByShelf, setShowBooksByShelf] = useState(false);

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorNewUser, setErrorNewUser] = useState('');

    const [showCreateNotif, setShowCreateNotif] = useState(false);

    const theme = useSelector(selectTheme);
    const user = useSelector(selectUser);

    const nbBooksByShelf = useSelector(selectNbBooksByShef);

    useEffect(() => {
        if (user === null) {
            dispatch(fetchUser());
        }
    }, [])

    useEffect(() => {
        if (user && user.isAdmin) {
            loadAdminScript();
        }
    }, [user])

    useEffect(() => {
        if (showCreateNotif) {
            initTinymce();
        }
    }, [showCreateNotif])

    useEffect(() => {
        setIsMenuParam(location.pathname === '/param' ? true : false);
        switch (location.pathname) {
            case '/param/tags':
                setTitle('Tags');
                break;
            case '/param/subscriptions':
                setTitle('Abonnements');
                break;
            case '/param/history':
                setTitle('Historique');
                break;
            case '/param/shelves':
                setTitle('Étagères');
                break;
            case '/param/explanation':
                setTitle('Tout savoir sur l\'application');
                break;
            default:
                setTitle('');
                break;
        }
    }, [location])

    const loadAdminScript = () => {
        const tinymceScript = document.createElement('script');
        tinymceScript.src = './tinymce/tinymce.min.js';
        document.body.insertAdjacentElement('beforeend', tinymceScript)
        tinymceScript.addEventListener('load', () => {
            const listPluginScript = document.createElement('script');
            listPluginScript.src = './tinymce/plugins/lists/plugin.min.js';
            document.body.insertAdjacentElement('beforeend', listPluginScript)

            const tablePluginScript = document.createElement('script');
            tablePluginScript.src = './tinymce/plugins/table/plugin.min.js';
            document.body.insertAdjacentElement('beforeend', tablePluginScript)
        })
    }

    const initTinymce = () => {

        // @ts-ignore
        tinymce.init({
            selector: 'textarea#tinymceEditor',
            height: window.innerHeight * 0.8 - 200,
            menubar: false,
            plugins: ["lists"],
            toolbar:
                'bold italic underline backcolor | table | bullist numlist | ' +
                'alignleft aligncenter alignright alignjustify | outdent indent | ' +
                'removeformat | ' +
                'undo redo | ',
        });

    }

    const logout = () => {
        fetchLogout();
        navigate('/connection');
    }

    /**
     * Nouvel utilisateur
     */
    const createUser = (e: any) => {
        e.preventDefault();
        if (errorNewUser) {
            return;
        }
        dispatch(fetchCreateUser(email, newPassword, () => {
            setShowNewUserModal(false);
        }, (error: string) => {
            setErrorNewUser(error === 'NO_VALID_PASSWORD' ? 'Mot de passe non valide : au minimum 6 caractères.' : error === 'NO_VALID_MAIL' ? 'L\'email n\'est pas valide.' : error ? error : 'Une erreur est survenue.')
        }));
    }

    const changeEmail = (e: any) => {
        const value = e.currentTarget.value;
        setEmail(value);
    }

    let timeoutChangeNewPassword: any = () => { }

    const changeConfirmNewPassword = (e: any) => {
        const value = e.currentTarget.value;
        clearTimeout(timeoutChangeNewPassword);
        setConfirmNewPassword(value);
        timeoutChangeNewPassword = setTimeout(() => {
            setErrorNewUser(newPassword && value && newPassword !== value ? 'Veuillez insérer le même mot de passe.' : '')
        }, 700)
    }

    const changePasswordNewUser = (e: any) => {
        const value = e.currentTarget.value;
        clearTimeout(timeoutChangeNewPassword);
        setNewPassword(value);
        timeoutChangeNewPassword = setTimeout(() => {
            setErrorNewUser(value && confirmNewPassword && value !== confirmNewPassword ? 'Veuillez insérer le même mot de passe.' : '')
        }, 700)
    }

    /**
     * Changement mot de passe
     */
    const sendNewPassword = (e: any) => {
        e.preventDefault();
        if (errorPassword) {
            return;
        }
        dispatch(fetchChangePassword(password, () => setShowPasswordModal(false)));
    }

    let timeoutChangePassword: any = () => { }

    const changeConfirmPassword = (e: any) => {
        const value = e.currentTarget.value;
        clearTimeout(timeoutChangePassword);
        setConfirmPassword(value);
        timeoutChangePassword = setTimeout(() => {
            setErrorPassword(password && value && password !== value ? 'Veuillez insérer le même mot de passe.' : '')
        }, 700)
    }

    const changeNewPassword = (e: any) => {
        const value = e.currentTarget.value;
        clearTimeout(timeoutChangePassword);
        setPassword(value);
        timeoutChangePassword = setTimeout(() => {
            setErrorPassword(value && confirmPassword && value !== confirmPassword ? 'Veuillez insérer le même mot de passe.' : '')
        }, 700)
    }

    const createNotif = () => {
        const notifTypeSelect: HTMLSelectElement | null = document.querySelector('.paramScreen select.notifType');
        // @ts-ignore
        const text = tinymce.activeEditor.getContent();
        const notifType: any = notifTypeSelect ? notifTypeSelect.value : '';
        dispatch(fetchCreateNotif(notifType, text, () => {
            // @ts-ignore
            tinymce.activeEditor.destroy();
            setShowCreateNotif(false);
        }))

    }

    return (
        <div className="paramScreen">
            <header>
                <div>
                    <Link to={isMenuParam ? "/books" : "/param"}><i className="bi bi-arrow-left"></i></Link>
                    <h1>Paramètres {title && <><i className="bi bi-circle-fill"></i><span>{title}</span></>}</h1>
                </div>
            </header>
            <main>
                <Outlet />
                {isMenuParam &&
                    <>
                        <ul className="menu-param">
                            <li><Link to="/param/shelves"> Modifier les étagères</Link></li>
                            <li><Link to="/param/tags" >Modifier les tags</Link></li>
                            <li><Link to="/param/subscriptions"> Modifier les abonnements</Link></li>
                            <li><a onClick={() => setShowBooksByShelf(true)}> Affichage de l'accueil</a></li>
                            <li><a onClick={() => setShowPasswordModal(true)}> Changer votre mot de passe</a></li>
                            <li><Link to="/param/history"> Historique</Link></li>
                            <li><Link to="/explanation"> Afficher l'aide</Link></li>
                            <li><button onClick={() => dispatch(setTheme(theme === 'dark' ? 'basic' : 'dark'))}>Passer l'application en mode {theme === 'dark' ? 'classique' : 'sombre'}</button></li>
                            {user && user.isAdmin && <li>
                                <button onClick={() => setShowNewUserModal(true)}>Ajouter un utilisateur</button>
                                <button onClick={() => setShowCreateNotif(true)}>Ajouter une notification</button>
                            </li>}
                            <li><button onClick={() => logout()}>Déconnexion</button></li>
                        </ul>
                        {showBooksByShelf &&
                            <BottomModal
                                title="Affichage de l'accueil"
                                onClose={() => setShowBooksByShelf(false)}
                            >
                                <div className="homeDisplayParams">
                                    <div>
                                        <strong>Limite de livres avant l'apparition d'un bouton "voir plus" :</strong>
                                        <i className="moreInfo">Ce paramètre peut régler des problèmes de performance.</i>
                                        <select value={nbBooksByShelf} onChange={(e) => dispatch(setNbBooksByShef(parseInt(e.currentTarget.value)))}>
                                            <option value="0">Aucune limite</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="150">150</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                </div>
                            </BottomModal>
                        }
                        {showNewUserModal &&
                            <BottomModal
                                title="Nouvel utilisateur"
                                onClose={() => setShowNewUserModal(false)}
                            >
                                <div className="newUserContainer">
                                    {errorNewUser && <div className="errorMsg">{errorNewUser}</div>}
                                    <form onSubmit={(e) => createUser(e)}>
                                        <div>
                                            <label>Email: </label>
                                            <input type="email" value={email} placeholder="Nouvel email" onChange={e => changeEmail(e)} />
                                        </div>
                                        <div>
                                            <label>Mot de passe : </label>
                                            <input type="password" value={newPassword} placeholder="Nouveau mot de passe" onChange={e => changePasswordNewUser(e)} />
                                        </div>
                                        <label>Veuillez retaper le mot de passe : </label>
                                        <input type="password" placeholder="Confirmation du mot de passe" value={confirmNewPassword} onChange={e => changeConfirmNewPassword(e)} />
                                        <button>Enregistrer</button>

                                    </form>
                                </div>
                            </BottomModal>
                        }
                        {showPasswordModal &&
                            <BottomModal
                                title="Changer de mot de passe"
                                onClose={() => setShowPasswordModal(false)}
                            >
                                <div className="changePasswordContainer">
                                    {errorPassword && <div className="errorMsg">{errorPassword}</div>}
                                    <form onSubmit={(e) => sendNewPassword(e)}>
                                        <div>
                                            <label>Veuillez ajouter votre nouveau mot de passe : </label>
                                            <input type="password" value={password} placeholder="Nouveau mot de passe" onChange={e => changeNewPassword(e)} />
                                        </div>
                                        <label>Veuillez retaper le mot de passe : </label>
                                        <input type="password" placeholder="Confirmation du mot de passe" value={confirmPassword} onChange={e => changeConfirmPassword(e)} />
                                        <button>Enregistrer</button>

                                    </form>
                                </div>
                            </BottomModal>
                        }
                        {showCreateNotif &&
                            <BottomModal
                                title="Créer une notification"
                                onClose={() => {
                                    setShowCreateNotif(false);
                                    // @ts-ignore
                                    tinymce.activeEditor.destroy();
                                }}
                            >
                                <div className="createNotifContainer">
                                    <div>
                                        <label><strong>Type :</strong></label>
                                        <select className="notifType"><option value="APP_UPDATE">Mise à jour</option></select>
                                    </div>
                                    <div>
                                        <textarea id="tinymceEditor" />
                                    </div>
                                    <button onClick={() => createNotif()}>Enregistrer</button>
                                </div>
                            </BottomModal>
                        }
                    </>
                }
            </main>
        </div>
    )
}
