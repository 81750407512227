import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface User {
    isAdmin: boolean
}

export interface UserState {
    systemMessage: string | null,
    errorMessage: string | null,
    theme: 'basic' | 'dark',
    user: User | null,
    nbBooksByShef: number,
    nbAddingMoreBooks: number
}

const localStorageTheme: any = window.localStorage.getItem('theme');
const localStorageNbBooksByShelf: any = window.localStorage.getItem('nbBooksByShef');

const initialState: UserState = {
    systemMessage: null,
    errorMessage: null,
    theme: localStorageTheme && ['basic', 'dark'].indexOf(localStorageTheme) !== -1 ? localStorageTheme : 'basic',
    user: null,
    nbBooksByShef: localStorageNbBooksByShelf ? parseInt(localStorageNbBooksByShelf) : 0,
    nbAddingMoreBooks: 20
};




export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setSystemMessage: (state, action) => {
            state.systemMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setTheme: (state, action) => {
            window.localStorage.setItem('theme', action.payload);
            state.theme = action.payload;
        },
        setNbBooksByShef: (state, action) => {
            window.localStorage.setItem('nbBooksByShef', action.payload);
            state.nbBooksByShef = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }


    }

});

export const { setSystemMessage, setErrorMessage, setTheme, setUser, setNbBooksByShef } = userSlice.actions;

export const selectSystemMessage = (state: RootState) => state.user.systemMessage;
export const selectErrorMessage = (state: RootState) => state.user.errorMessage;
export const selectTheme = (state: RootState) => state.user.theme;
export const selectUser = (state: RootState) => state.user.user;
export const selectNbBooksByShef = (state: RootState) => state.user.nbBooksByShef;
export const selectNbAddingMoreBooks = (state: RootState) => state.user.nbAddingMoreBooks;

export default userSlice.reducer;
