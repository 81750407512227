import React from 'react';
import { useSelector } from 'react-redux';
import { selectSystemMessage } from '../../slices/userSlice';

import './systemMessage.css';

export function SystemMessage() {

    const message: string | null = useSelector(selectSystemMessage);
    if (message === null) {
        return <></>;
    }

    return <div className="systemMessageModal">{message}</div>
}
