import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchComments } from '../../app/fetchApi';
import './bookComments.css';

export function BookComments() {
    const [params] = useSearchParams();
    const url = params.getAll('url');
    const dispatch = useDispatch();
    const [bookUrl, setBookUrl] = useState<null | string>(typeof url !== 'undefined' || url[0] ? url[0] + '/commentaires' : null);
    const [page, setPage] = useState<number>(1);
    const [nbPage, setNbPage] = useState(1);
    const [comments, setComments] = useState([]);

    const nbCommentsByPage = 10;

    useEffect(() => {
        if (!bookUrl) {
            return;
        }
        dispatch(fetchComments(bookUrl, page, (comments: [], pagination: number) => {
            if (pagination && page !== pagination) {
                setNbPage(pagination);
            }
            setComments(comments);
            const main = document.querySelector('main');
            if (main) {
                main.scrollTop = 0;
            }
        }))

    }, [bookUrl, page]);

    useEffect(() => {
        const spoilersentence = document.querySelectorAll('.spoilersentence');
        for (let i = 0; i < spoilersentence.length; i++) {
            spoilersentence[i].addEventListener('click', (e) => {
                const self: any = e.currentTarget;
                const secrettext = self.parentElement.querySelector('.secrettext');
                if (secrettext) {
                    secrettext.style.display = 'block';
                }
                self.remove();
            })
        }
    }, [comments])

    const getPagination = () => {

        let options = [];
        let index = 0;
        while (index < nbPage) {
            options.push(<option key={index} value={index + 1}>Page {index + 1}</option>);
            index++;
        }

        return options;
    }

    const pagination = getPagination().map(option => option);
    return (
        <div className="bookComments">
            <header>
                <div>
                    <a onClick={() => window.history.back()}><i className="bi bi-arrow-left"></i></a>
                    <h1>Commentaires</h1>
                </div>
            </header>
            <main>
                <select value={page} onChange={(e: any) => setPage(e.currentTarget.value)}>
                    {pagination}
                </select>
                {comments.map((comment, index) =>
                    <div key={index}>
                        <strong>Commentaire {(index + 1) + (page - 1) * nbCommentsByPage}</strong>
                        <div dangerouslySetInnerHTML={{ __html: comment }} />
                    </div>
                )}
                {comments.length !== 0 &&
                    <select value={page} onChange={(e: any) => setPage(e.currentTarget.value)}>
                        {pagination}
                    </select>
                }
            </main>
        </div>
    )
}
