
import React, { MouseEventHandler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddBooks, fetchChangeShelf } from '../../app/fetchApi';
import { selectShelves, Shelf } from '../../slices/shelvesSlice';
import { BottomModal } from '../BottomModal/BottomModal';
import './changeShelf.css';

interface changeShefProps {
    bookId: number | null | number[],
    currentShelf: number | null,
    onClose: Function,
    callback: Function,
    booksData?: any[]

}

export function ChangeShef(props: changeShefProps) {

    const shelves = useSelector(selectShelves);
    const [sameForVolume, setSameForVolume] = useState(false);
    const dispatch = useDispatch();

    const onClick = (shelf: Shelf) => {
        if (props.bookId !== null) {
            dispatch(fetchChangeShelf(shelf.id, typeof props.bookId === 'number' ? [props.bookId] : sameForVolume ? props.bookId : [props.bookId[0]], props.callback))
        } else {
            fetchAddBooks(props.booksData ? props.booksData : [], shelf.id, props.callback);

        }

        props.onClose();
    }

    return (
        <BottomModal
            title="Changer d'étagère"
            onClose={() => props.onClose()}
        >
            <>
                {typeof props.bookId !== 'number' && props.bookId !== null && props.bookId.length > 1 && <label className="shelves-list-option"><input type="checkbox" checked={sameForVolume} onChange={(e) => setSameForVolume(e.currentTarget.checked)} />Faire de même pour les autres tomes de la même étagère</label>}
                <ul className="shelves-list">
                    {shelves.map(shelf =>
                        <li key={shelf.id} onClick={() => onClick(shelf)}>
                            {shelf.name}
                            {shelf.id === props.currentShelf && <i className="bi bi-check-circle-fill"></i>}
                        </li>
                    )}
                </ul>
            </>

        </BottomModal>

    )
}
