import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BOOKNODE_URL, fetchCheckUpdate, fetchInternetBook, fetchRemoveBook, fetchSeriesFollow, fetchShelves, fetchTags, fetchUnFollow } from '../../app/fetchApi';
import { BookCommentModal } from '../../features/BookCommentModal/BookCommentModal';
import { BookItem } from '../../features/BookItem/BookItem';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import { ChangeShef } from '../../features/ChangeShelf/ChangeShelf';
import { TagsModal } from '../../features/TagsModal/TagsModal';
import { DateFormat } from '../../utils/Utils';
import './book.css';
import { BookSkeleton } from './BookSkeleton';

interface Book {
    authorUrl: string,
    authorname: string,
    bookUrl: string,
    commentsUrl: string,
    extractUrl: string,
    image: string,
    name: string,
    nbComments: number,
    nbExtracts: number,
    peopleLikeToo: peopleLikeTooBook[],
    publishDate: PublishDate,
    seriesBooks: seriesBook[],
    seriesUrl: string,
    seriesname: string,
    synopsis: string,
    themes: string[],
    website: 'booknode',
    hasSubscription: boolean
}

interface PublishDate {
    CANADIAN: string,
    FRENCH: string,
    ENGLISH: string
}

interface peopleLikeTooBook {
    name: string,
    image: string,
    url: string
    database?: BookDatabase
}

interface seriesBook {
    url: string,
    description: string,
    image: string,
    name: string,
    database?: BookDatabase
}

// pareil que interface BookDetail
interface BookDatabase {
    id: number,
    url: string,
    website: 'booknode',
    authorUrl: string,
    authorname: string,
    comment: string | null,
    image: string,
    name: string,
    publishDate: PublishDate,
    seriesName: string,
    seriesUrl: string,
    shelfId: number,
    shelfName: string,
    synopsis: string,
    tags: number[],

}

interface BookProps {
    simpleForm?: boolean,
    bookUrl?: string,
    website?: 'booknode' | string
}

export function Book(props: BookProps) {
    const [params, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const url = params.getAll('url');

    const website = params.getAll('website');
    const [bookUrl, setBookUrl] = useState<null | string>(props.bookUrl ? props.bookUrl.replace(BOOKNODE_URL, '') : typeof url !== 'undefined' && url[0] ? url[0].replace(BOOKNODE_URL, '') : null);
    const [bookWebsite, setBookWebsite] = useState<null | string>(props.website ? props.website : typeof website !== 'undefined' || website[0] ? website[0] : null);
    const [book, setBook] = useState<null | Book>(null);
    const [bookDatabase, setBookDatabase] = useState<null | BookDatabase>(null);
    const seriesBooksRef = useRef(null);
    const [showShelfForExternalBooks, setShowShelfForExternalBooks] = useState<null | any>(null);
    const [seriesBooksDataForAdding, setSeriesBooksDataForAdding] = useState<any[]>([]);

    useEffect(() => {
        if (props.bookUrl) {
            setBookUrl(props.bookUrl);
            return;
        }

        const urls = params.getAll('url');
        if (typeof urls !== 'undefined' && urls[0] && urls[0] !== bookUrl) {
            setBookUrl(urls[0]);
        }
    }, [params, props.bookUrl])

    useEffect(() => {
        fetchBook();
    }, [bookUrl])

    useEffect(() => {
        dispatch(fetchShelves());
        dispatch(fetchTags());
    }, [])

    const fetchBook = () => {
        if (bookUrl === null || bookWebsite === null) {
            return;
        }

        const oldUrl = book?.bookUrl;
        dispatch(fetchInternetBook(bookUrl, bookWebsite, (book: Book, bookDatabase: BookDatabase) => {
            if (oldUrl !== bookUrl) {
                let mainElement = document.querySelector('.theme');
                if (mainElement) {
                    mainElement.scrollTop = 0
                }
            }

            if (bookDatabase) {
                dispatch(fetchCheckUpdate(book));
            }

            setBook(book);
            setBookDatabase(bookDatabase);
            setSeriesBooksDataForAdding(() => {
                const booksData: any[] = [];
                if (book === null || typeof book.seriesBooks === 'undefined' || !book.seriesBooks) {
                    return booksData;
                }

                book.seriesBooks.map(seriesBook => {
                    if (!seriesBook.database || (seriesBook.database && !seriesBook.database.shelfName)) {
                        booksData.push({ noData: true, url: seriesBook.url, website: book.website });
                    }
                })

                return booksData;
            });
        }));
    }

    const changeBookPage = (url: string) => {
        navigate(`/book?url=${url}&website=${book !== null ? book.website : 'booknode'}`);
    }

    if (book === null) {
        return <BookSkeleton />;
    }

    return (
        <div className="bookScreen">
            {!props.simpleForm && <NavButtons />}

            <HeaderImage book={book} />

            <BookActions simpleForm={props.simpleForm} bookDatabase={bookDatabase} fetchBook={fetchBook} book={book} />

            {showShelfForExternalBooks &&
                <ChangeShef
                    bookId={showShelfForExternalBooks.bookId ? showShelfForExternalBooks.bookId : null}
                    currentShelf={showShelfForExternalBooks.shelfId ? showShelfForExternalBooks.shelfId : null}
                    callback={() => fetchBook()}
                    onClose={() => setShowShelfForExternalBooks(null)}
                    booksData={showShelfForExternalBooks.booksData ? showShelfForExternalBooks.booksData : undefined}
                />
            }

            <div className="body">
                <h1>{book.name}</h1>
                <InfoBook book={book} simpleForm={props.simpleForm} />

                {!props.simpleForm && <CommentsAndExtracts book={book} />}


                <div>
                    <strong className="subTitle">Les thèmes</strong>
                    <div>{book.themes.map((theme, index) => theme + (index + 1 !== book.themes.length ? ' - ' : ''))}</div>
                </div>

                <Series
                    book={book}
                    seriesBooksDataForAdding={seriesBooksDataForAdding}
                    seriesBooksRef={seriesBooksRef}
                    changeBookPage={changeBookPage}
                    setShowShelfForExternalBooks={setShowShelfForExternalBooks}
                />

                {!props.simpleForm &&
                    <PeopleLikeToo
                        book={book}
                        changeBookPage={changeBookPage}
                        setShowShelfForExternalBooks={setShowShelfForExternalBooks}
                    />
                }

                {props.simpleForm && <div className="formCompleteLink"><a className="link" onClick={() => bookUrl ? changeBookPage(bookUrl) : {}}>Voir la fiche internet complète</a></div>}
            </div>
        </div >
    );
}

function NavButtons() {
    const navigate = useNavigate();

    return (
        <div className="navButtons">
            <button onClick={() => navigate('/books')}><i className="bi bi-house-fill"></i></button>
            <button onClick={() => window.history.back()}><i className="bi bi-arrow-left"></i></button>
        </div>
    )
}

interface ImageContainerProps {
    book: Book
}

function HeaderImage(props: ImageContainerProps) {
    const book = props.book;
    return (
        <div className="imageContainer" style={{ backgroundImage: 'url(' + book.image + ')' }}>
            <div>
                {book.seriesname ?
                    <>
                        <span>Série</span>
                        <br />
                        <span>{book.seriesname}</span>
                    </>
                    : <span>{book.name}</span>
                }
            </div>
        </div>
    )
}

interface BookActionsProps {
    bookDatabase: BookDatabase | null,
    book: Book,
    fetchBook: Function,
    simpleForm?: boolean
}

function BookActions(props: BookActionsProps) {
    const bookDatabase = props.bookDatabase;
    const simpleForm = props.simpleForm;

    const [showShelf, setShowShelf] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const dispatch = useDispatch();

    const removeBook = () => {
        if (bookDatabase === null) {
            return;
        }

        setShowMore(false);
        dispatch(fetchRemoveBook(bookDatabase.id, () => {
            props.fetchBook();
        }));
    }

    const followOrUnFollow = () => {
        if (props.book.hasSubscription) {
            dispatch(fetchUnFollow(props.book.seriesUrl, 'series', () => {
                props.fetchBook();
            }));
            return;
        }

        const booksUrl: string[] = [];
        props.book.seriesBooks.map(seriesBook => {
            booksUrl.push(seriesBook.url);
        });

        dispatch(fetchSeriesFollow(props.book.seriesUrl, props.book.website, props.book.seriesname, booksUrl, () => {
            props.fetchBook();
        }));
    }

    return (
        <>
            <div className="bookActions">
                {bookDatabase === null &&
                    <button onClick={() => setShowShelf(true)}>
                        <i className="bi bi-book"></i> <span>Ajouter</span>
                    </button>
                }

                {bookDatabase !== null && (
                    <>
                        <button onClick={() => setShowShelf(true)}>
                            <i className="bi bi-book"></i> <span>{bookDatabase.shelfName}</span>
                        </button>
                        {!simpleForm &&
                            <>
                                <button onClick={() => setShowTags(true)}>
                                    <i className="bi bi-tag-fill"></i><span>Tags</span>
                                </button>
                                <button onClick={() => setShowComment(true)}>
                                    <i className="bi bi-chat-fill"></i><span>Commentaire</span>
                                </button>
                                <button onClick={() => setShowMore(true)}>
                                    <i className="bi bi-three-dots-vertical"></i><span>Voir plus</span>
                                </button>
                            </>
                        }
                    </>
                )}
            </div>

            {showShelf && <ChangeShef
                bookId={bookDatabase !== null ? bookDatabase.id : null}
                currentShelf={bookDatabase !== null ? bookDatabase.shelfId : null}
                callback={() => props.fetchBook()}
                onClose={() => setShowShelf(false)}
                booksData={[props.book]}
            />}

            {showTags && bookDatabase !== null && <TagsModal
                bookId={bookDatabase.id}
                selectedTagsId={bookDatabase.tags ? bookDatabase.tags : []}
                onClose={() => setShowTags(false)}
                callback={() => props.fetchBook()}
            />}

            {showComment && bookDatabase !== null && <BookCommentModal
                bookId={bookDatabase.id}
                comment={bookDatabase.comment ? bookDatabase.comment : ''}
                onClose={() => setShowComment(false)}
                callback={() => props.fetchBook()}
            />}

            {showMore &&
                <BottomModal
                    title="Plus d'options"
                    onClose={() => setShowMore(false)}
                >
                    <ul className="moreOptions-list">
                        {bookDatabase !== null && <li><i className="bi bi-arrow-clockwise"></i> <strong>Mettre à jour</strong></li>}
                        <li onClick={() => followOrUnFollow()}>
                            <i className="bi bi-link"></i>
                            <div>
                                {!props.book.hasSubscription ?
                                    <>
                                        <strong>Suivre la série</strong>
                                        <span>Tu seras notifier dès qu'un nouveau tome sera ajouté.</span>
                                    </>
                                    : <strong>Ne plus suivre la série</strong>
                                }
                            </div>
                        </li>
                        {bookDatabase !== null && <li onClick={() => removeBook()}><i className="bi bi-eraser-fill"></i><strong>Supprimer le livre de mes étagères</strong></li>}
                    </ul>

                </BottomModal>
            }
        </>
    )
}

interface InfoBookProps {
    book: Book,
    simpleForm?: boolean
}

function InfoBook(props: InfoBookProps) {
    const book = props.book;
    const simpleForm = props.simpleForm;
    const navigate = useNavigate();

    let frenchDate: any = typeof book.publishDate !== 'undefined' && book.publishDate.FRENCH !== '' ? new DateFormat(book.publishDate.FRENCH) : '';
    let englishDate: any = typeof book.publishDate !== 'undefined' && book.publishDate.ENGLISH !== '' ? new DateFormat(book.publishDate.ENGLISH) : '';
    let canadianDate: any = typeof book.publishDate !== 'undefined' && book.publishDate.CANADIAN !== '' ? new DateFormat(book.publishDate.CANADIAN) : '';

    return (
        <>
            <div>
                <strong>Auteur</strong><br />
                <span>{simpleForm ? book.authorname : <a className="link" onClick={() => navigate('/author?url=' + book.authorUrl + '&website=booknode')}>{book.authorname}</a>}</span>
            </div>

            <div className="dateSection">
                <div>
                    <strong>France</strong><br />
                    <span>{frenchDate && frenchDate.format('d/m/Y')}</span>
                </div>
                <div>
                    <strong>USA/Angl.</strong><br />
                    <span>{englishDate && englishDate.format('d/m/Y')}</span>
                </div>
                <div>
                    <strong>Canada</strong><br />
                    <span>{canadianDate && canadianDate.format('d/m/Y')}</span>
                </div>
            </div>

            <div>
                <strong>Description</strong>
                <div dangerouslySetInnerHTML={{ __html: book.synopsis }} />
            </div>
        </>
    )
}

interface SeriesProps {
    book: Book,
    seriesBooksDataForAdding: any[],
    seriesBooksRef: any,
    setShowShelfForExternalBooks: Function,
    changeBookPage: Function
}

function Series(props: SeriesProps) {
    const book = props.book;
    if (!book.seriesUrl || !book.seriesBooks || book.seriesBooks.length === 0) {
        return <></>;
    }

    const seriesBooksDataForAdding = props.seriesBooksDataForAdding;
    const seriesBooksRef = props.seriesBooksRef;
    const setShowShelfForExternalBooks = props.setShowShelfForExternalBooks;
    const changeBookPage = props.changeBookPage;

    return (
        <div>
            <strong className="subTitle">La série</strong>
            {seriesBooksDataForAdding.length > 0 &&
                <button
                    className="addSeriesBooks"
                    onClick={() => setShowShelfForExternalBooks({ booksData: seriesBooksDataForAdding })}
                >
                    <i className="bi bi-plus-circle"></i>
                </button>
            }
            <br />
            <div className="seriesBooks" ref={seriesBooksRef}>
                {book.seriesBooks.map(seriesBook => {
                    return <BookItem
                        key={seriesBook.url}
                        name={seriesBook.name}
                        image={seriesBook.image}
                        nbBooks={1}
                        onClick={() => {
                            changeBookPage(seriesBook.url)
                        }}
                        width={'7.3rem'}
                        shelfName={seriesBook.database ? seriesBook.database.shelfName : ''}
                        onLongPress={() => {
                            if (!seriesBook.database || (seriesBook.database && !seriesBook.database.shelfName)) {
                                setShowShelfForExternalBooks({ booksData: [{ noData: true, url: seriesBook.url, website: book.website }] });
                            } else {
                                setShowShelfForExternalBooks({ bookId: seriesBook.database.id, shelfId: seriesBook.database.shelfId });

                            }
                        }}
                    />
                })}
            </div>
        </div>
    )
}

interface CommentsAndExtractsProps {
    book: Book
}

function CommentsAndExtracts(props: CommentsAndExtractsProps) {
    const book = props.book;
    const navigate = useNavigate();

    return (
        <div className="linkSection">
            <a
                className="link"
                onClick={() => navigate('/bookComments?url=' + book.bookUrl)}
            >
                {book.nbComments > 1 ? book.nbComments + ' commentaires' : book.nbComments + ' commentaire'}
            </a>
            <a
                className="link"
                onClick={() => navigate('/bookExtracts?url=' + book.bookUrl)}
            >
                {book.nbExtracts > 1 ? book.nbExtracts + ' extraits' : book.nbExtracts + ' extrait'}
            </a>
        </div>
    )
}

interface PeopleLikeTooProps {
    book: Book,
    changeBookPage: Function,
    setShowShelfForExternalBooks: Function
}

function PeopleLikeToo(props: PeopleLikeTooProps) {
    const book = props.book;
    const changeBookPage = props.changeBookPage;
    const setShowShelfForExternalBooks = props.setShowShelfForExternalBooks;

    return (
        <div>
            <strong>Les gens aiment aussi</strong>
            <div className="peopleLikeBooks">
                {book.peopleLikeToo && book.peopleLikeToo.map(peopleLikeBook =>
                    <BookItem
                        key={peopleLikeBook.url}
                        name={peopleLikeBook.name}
                        image={peopleLikeBook.image}
                        nbBooks={1}
                        onClick={() => changeBookPage(peopleLikeBook.url)}
                        shelfName={peopleLikeBook.database ? peopleLikeBook.database.shelfName : ''}
                        width="100%"
                        onLongPress={() => {
                            if (!peopleLikeBook.database || (peopleLikeBook.database && !peopleLikeBook.database.shelfName)) {
                                setShowShelfForExternalBooks({ booksData: [{ noData: true, url: peopleLikeBook.url, website: book.website }] });
                            } else {
                                setShowShelfForExternalBooks({ bookId: peopleLikeBook.database.id, shelfId: peopleLikeBook.database.shelfId });

                            }
                        }}

                    />
                )}
            </div>
        </div>
    )
}
