import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { selectTag, Tag as TagEntity } from '../../slices/tagsSlice';
import './tag.css';

interface TagProps {
    tagId: number,
    onClick?: MouseEventHandler
}

export function Tag(props: TagProps) {
    const tag: TagEntity | undefined = useSelector((rootState: RootState) => selectTag(rootState, props.tagId));

    if (typeof tag === 'undefined') {
        return <></>;
    }

    return (
        <div className="tagItem" onClick={props.onClick} style={{ background: tag.color ? tag.color : 'grey' }}>
            <i className="bi bi-tag-fill"></i>{tag.name}
        </div>
    )
}
