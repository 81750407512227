import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BOOKNODE_URL, fetchBooksByTheme, fetchThemesList } from '../../app/fetchApi';
import './searchByTheme.css';

interface Theme {
    url: string,
    name: string
}

interface Book {
    name: string,
    image: string,
    url: string,
    authorname: string,
    authorUrl: string,
}

export function SearchByTheme() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [themes, setThemes] = useState<Theme[]>([]);
    const [subThemes, setSubThemes] = useState<any>({});
    const [selectedThemeUrl, setSelectedThemeUrl] = useState<null | string>(null);
    const [selectedSubThemeUrl, setSelectedSubThemeUrl] = useState<null | string>(null);
    const [page, setPage] = useState(1);
    const [books, setBooks] = useState<Book[]>([]);

    useEffect(() => {
        dispatch(fetchThemesList((themes: [], subThemes: {}) => {
            setThemes(themes);
            setSubThemes(subThemes);
        }));
    }, [])

    useEffect(() => {
        if (!selectedSubThemeUrl && !selectedThemeUrl) {
            return;
        }

        dispatch(fetchBooksByTheme(selectedSubThemeUrl ? selectedSubThemeUrl : selectedThemeUrl ? selectedThemeUrl : '', page, (books: Book[]) => {
            const mainElement: any = document.querySelector('main');
            mainElement.scrollTop = 0;
            setBooks(books);
        }));
    }, [selectedThemeUrl, selectedSubThemeUrl, page])

    const changeTheme = (url: string) => {
        setSelectedThemeUrl(url);
        setSelectedSubThemeUrl(null);
    }

    const prev = () => {
        if (page === 1) {
            return;
        }

        setPage(page - 1);
    }

    const next = () => {
        if (page > 1 && books.length === 0) {
            return;
        }

        setPage(page + 1);

    }

    return (
        <div className="searchByTheme">
            <header>
                <div>
                    <a onClick={() => window.history.back()}><i className="bi bi-arrow-left"></i></a>
                    <h1>Recherche par thèmes</h1>
                </div>
            </header>
            <main>
                <ul className="horizontalScroll">
                    <li><strong>Thèmes :</strong></li>
                    {themes.map(theme => <li key={theme.url} className={`${selectedThemeUrl === theme.url ? 'selected' : ''}`} onClick={() => changeTheme(theme.url)}>{theme.name}</li>)}
                </ul>
                {selectedThemeUrl && subThemes && typeof subThemes[selectedThemeUrl] !== 'undefined' && subThemes[selectedThemeUrl].length > 0 &&
                    <ul className="horizontalScroll">
                        <li><strong>Sous-thèmes :</strong></li>
                        {subThemes[selectedThemeUrl].map((theme: Theme) => <li key={theme.url} className={`${selectedSubThemeUrl === theme.url ? 'selected' : ''}`} onClick={() => setSelectedSubThemeUrl(theme.url)}>{theme.name}</li>)}
                    </ul>
                }

                {!selectedThemeUrl && <div className="selectedThemeMessage">Veuillez sélectionner un thème.</div>}
                {books.map((book) =>
                    <div key={book.url} className="searchBook">
                        <div onClick={() => navigate('/book?url=' + book.url.replace(BOOKNODE_URL, '') + '&website=booknode')} className="imageSearchBook" style={{ backgroundImage: 'url(' + book.image + ')' }}></div>
                        <div>
                            <strong onClick={() => navigate('/book?url=' + book.url.replace(BOOKNODE_URL, '') + '&website=booknode')}>{book.name}</strong>
                            {book.authorname && <a className="link" onClick={() => navigate('/author?url=' + book.authorUrl + '&website=booknode')}>{book.authorname}</a>}
                        </div>
                    </div>
                )}

                {selectedThemeUrl &&
                    <footer>
                        <button className={`${page === 1 ? 'disabled' : ''}`} onClick={() => prev()}><i className="bi bi-caret-left-fill"></i><span>Précédent</span></button>
                        <span>Page {page}</span>
                        <button className={`${page > 1 && books.length === 0 ? 'disabled' : ''}`} onClick={() => next()} ><span>Suivant</span><i className="bi bi-caret-right-fill"></i></button>
                    </footer>
                }
            </main>
        </div >
    )
}
