import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooksDetail, fetchDeleteBook, fetchHomeBooks } from '../../app/fetchApi';
import { selectShelves } from '../../slices/shelvesSlice';
import { DateFormat } from '../../utils/Utils';
import { BookCommentModal } from '../../features/BookCommentModal/BookCommentModal';
import { BottomModal } from '../../features/BottomModal/BottomModal';
import { Tag } from '../../features/Tag/Tag';
import './bottomBooksModal.css';
import { ChangeShef } from '../../features/ChangeShelf/ChangeShelf';
import { TagsModal } from '../../features/TagsModal/TagsModal';
import { Link, useNavigate } from 'react-router-dom';

interface BottomModalProps {
    title: string,
    booksId: number[],
    onClose: Function
}

interface PublishDate {
    CANADIAN: string,
    FRENCH: string,
    ENGLISH: string
}

interface BookDetail {
    id: number,
    url: string,
    website: 'booknode',
    authorUrl: string,
    authorname: string,
    comment: string | null,
    image: string,
    name: string,
    publishDate: PublishDate,
    seriesName: string,
    seriesUrl: string,
    shelfId: number,
    shelfName: string,
    synopsis: string,
    tags: number[]
}

export function BottomBooksModal(props: BottomModalProps) {
    const navigate = useNavigate();
    const [books, setBooks] = useState<BookDetail[] | null>(null);
    const [hasAuthorSubscription, setHasAuthorSubscription] = useState(false);
    const [showShelvesForBookId, setShowShelvesForBookId] = useState<number | null>(null);
    const [showCommentForBook, setShowCommentForBook] = useState<any | null>(null);
    const [showTagsForBook, setShowTagsForBook] = useState<any | null>(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<any | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const withAuthorSubscription = true;
        fetchBooksDetail(props.booksId, withAuthorSubscription, (booksDetail: BookDetail[], hasAuthorSubscription: boolean) => {
            setBooks(booksDetail);
            setHasAuthorSubscription(hasAuthorSubscription);
        });
    }, [props])

    const getCurrentShelfForBook = (bookId: number | null): number | null => {

        if (books === null || bookId === null) {
            return null;
        }
        const book = books.find(book => book.id === bookId);
        return book ? book.shelfId : null;
    }


    const deleteBook = (bookId: number) => {
        dispatch(fetchDeleteBook(bookId));
        setShowDeleteConfirm(null);
        if (books !== null && books.length === 1) {
            props.onClose();
        }
    }

    const getBooksIdForChangeShelf = () => {
        if (books === null || showShelvesForBookId === null) {
            return null;
        }

        const booksId = [showShelvesForBookId];
        books.map(book => {
            if (book.id !== showShelvesForBookId) {
                booksId.push(book.id);
            }
        });

        return booksId;
    }

    const firstBook: BookDetail | null = books !== null ? books[0] : null;

    return (
        <BottomModal
            title={props.title}
            subTitle={firstBook !== null && firstBook.authorUrl && firstBook.authorname ? (
                <>
                    <strong>Auteur :</strong> <a className="link" onClick={() => navigate('/author?url=' + firstBook.authorUrl + '&website=booknode')}>{firstBook.authorname}</a>
                    {hasAuthorSubscription && <><span className="followedAuthor">Vous suivez cet auteur.</span></>}
                </>
            ) : ''}
            onClose={() => props.onClose()}
        >

            {books !== null && books.map(book => <BookDetail
                key={book.id}
                book={book}
                changeShef={() => setShowShelvesForBookId(book.id)}
                changeComment={() => setShowCommentForBook({ id: book.id, comment: book.comment })}
                changeTags={() => setShowTagsForBook({ id: book.id, tags: book.tags })}
                deleteConfirm={() => setShowDeleteConfirm({ id: book.id, name: book.name })}
            />
            )}
            {showShelvesForBookId !== null && <ChangeShef
                bookId={getBooksIdForChangeShelf()}
                currentShelf={getCurrentShelfForBook(showShelvesForBookId)}
                callback={() => dispatch(fetchHomeBooks())}
                onClose={() => setShowShelvesForBookId(null)}

            />}
            {showCommentForBook !== null && <BookCommentModal
                bookId={showCommentForBook.id}
                comment={showCommentForBook.comment}
                onClose={() => setShowCommentForBook(null)}
                callback={() => dispatch(fetchHomeBooks())}
            />}
            {showTagsForBook !== null && <TagsModal
                bookId={showTagsForBook.id}
                selectedTagsId={showTagsForBook.tags ? showTagsForBook.tags : []}
                onClose={() => setShowTagsForBook(null)}
                callback={() => dispatch(fetchHomeBooks())}
            />}
            {showDeleteConfirm !== null && <BottomModal
                title="Confirmation de suppression"
                onClose={() => setShowDeleteConfirm(null)}
            >
                <div className="deleteConfirmModal">
                    <p>
                        Êtes-vous sûre de vouloir supprimer <br /><strong>"{showDeleteConfirm.name}"</strong> ?
                    </p>
                    <button onClick={() => deleteBook(showDeleteConfirm.id)}>Supprimer</button>
                </div>
            </BottomModal>
            }
        </BottomModal>

    )

}

interface BookDetailProps {
    book: BookDetail,
    changeShef: Function,
    changeComment: Function,
    deleteConfirm: Function,
    changeTags: Function
}

function BookDetail(props: BookDetailProps) {
    const book = props.book;

    let frenchDate: any = typeof book.publishDate !== 'undefined' && book.publishDate.FRENCH !== '' ? new DateFormat(book.publishDate.FRENCH) : '';
    let englishDate: any = typeof book.publishDate !== 'undefined' && book.publishDate.ENGLISH !== '' ? new DateFormat(book.publishDate.ENGLISH) : '';
    let canadianDate: any = typeof book.publishDate !== 'undefined' && book.publishDate.CANADIAN !== '' ? new DateFormat(book.publishDate.CANADIAN) : '';

    return <div className="bookdetail">
        <div className="bookdetail-header">
            <div className="imageContainer" style={{ backgroundImage: 'url(' + (book.image ? book.image : 'https://cdn.pixabay.com/photo/2017/05/13/09/04/question-2309040_1280.jpg') + ')' }}><Link to={`/book?url=${book.url}&website=${book.website}`} /></div>
            <div>
                <div className="bookdetail-title"> <Link to={`/book?url=${book.url}&website=${book.website}`}><strong>{book.name}</strong></Link></div>
                <strong className="bookdetail-dates">Date de publication</strong>
                {frenchDate && <div><strong>France</strong> {frenchDate.format('d/m/Y')}</div>}
                {englishDate && <div><strong>USA/Anglettre</strong> {englishDate.format('d/m/Y')}</div>}
                {canadianDate && <div><strong>Canada</strong> {canadianDate.format('d/m/Y')}</div>}
            </div>
        </div>
        <div className="bookdetail-buttons">
            <button className="bookdetail-action" onClick={() => props.changeShef()}>
                <i className="bi bi-book"></i> <span>Changer d'étagère</span>
            </button>
            <Link className="bookdetail-action" to={`/book?url=${book.url}&website=${book.website}`}>
                <i className="bi bi-journal-medical"></i><span>Fiche internet</span>
            </Link>
            <button className="bookdetail-action" onClick={() => props.deleteConfirm()}>
                <i className="bi bi-trash3-fill"></i><span>Supprimer</span>
            </button>
        </div>
        <div dangerouslySetInnerHTML={{ __html: book.synopsis }} />
        <div className="bookdetail-tags">
            <div>
                <button onClick={() => props.changeTags()}><strong>Tags</strong> <i className="bi bi-pencil-fill"></i></button>
            </div>
            {book.tags.map(tagId => <Tag key={tagId} tagId={tagId} />)}
            {!book.tags || book.tags.length === 0 && (
                <div>
                    Vous n'avez ajouté aucun tag.
                </div>
            )}
        </div>
        <div className="bookdetail-comment">
            <div>
                <button onClick={() => props.changeComment()}><strong>Mon commentaire</strong> <i className="bi bi-pencil-fill"></i></button>
            </div>
            <div>
                {book.comment ? book.comment : 'Vous n\'avez ajouté aucun commentaire.'}
            </div>
        </div>
    </div>;
}
