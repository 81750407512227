import React from 'react';
import './homeBookSkeleton.css';

export function HomeBookSkeleton() {
    return (
        <div className="homeBookSkeleton">
            <div className="menu">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="shelf">
                <div className="shelfName"></div>
                <div className="books">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="shelf">
                <div className="shelfName"></div>
                <div className="books">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="shelf">
                <div className="shelfName"></div>
                <div className="books">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}
