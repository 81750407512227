import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddTag, fetchChangeTags } from '../../app/fetchApi';
import { selectTags } from '../../slices/tagsSlice';
import { BottomModal } from '../BottomModal/BottomModal';
import './tagsModal.css';

interface TagsModalProps {
    bookId: number,
    selectedTagsId: number[],
    onClose: Function,
    callback: Function
}
export function TagsModal(props: TagsModalProps) {
    const tags = useSelector(selectTags)
    const newTagElement = useRef(null);
    const [selectedTags, setSelectedTags] = useState(props.selectedTagsId);

    const dispatch = useDispatch();

    const send = () => {
        dispatch(fetchChangeTags(props.bookId, selectedTags, props.callback));
        props.onClose();
    }

    const pushNewTag = () => {
        if (!newTagElement || !newTagElement.current) {
            return;
        }
        const tagInput: any = newTagElement.current;

        if (!tagInput.value) {
            return;
        }

        dispatch(fetchAddTag(tagInput.value));
        tagInput.value = '';
    }

    const toggleTag = (tagId: number) => {
        if (selectedTags.indexOf(tagId) === -1) {
            let newTags = selectedTags.slice();
            newTags.push(tagId);
            setSelectedTags(newTags);
            return;
        }
        let newTags = selectedTags.filter(tag => tag !== tagId);
        setSelectedTags(newTags);

    }

    return (
        <BottomModal
            title={'Sélectionner des tags'}
            onClose={() => props.onClose()}
        >
            <div className="tagsModal">
                <ul className="tags-list">
                    {tags.map(tag =>
                        <li
                            key={tag.id}
                            onClick={() => toggleTag(tag.id)}
                        >
                            {tag.name}
                            {selectedTags.indexOf(tag.id) !== -1 && <i className="bi bi-check-circle-fill"></i>}
                        </li>
                    )}
                </ul>
                <footer>
                    <div>
                        <input type="text" placeholder="Ajouter un tag" ref={newTagElement} />
                        <button className="pushNewTag" onClick={() => pushNewTag()}><i className="bi bi-arrow-up"></i></button>
                    </div>
                    <button className="sendBtn" onClick={() => send()}>Enregistrer</button>

                </footer>
            </div>
        </BottomModal>
    )
}
