import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAddTag, fetchTags, fetchUpdateTag } from '../../app/fetchApi';
import { selectTags, Tag as TagModel } from '../../slices/tagsSlice';
import { BottomModal } from '../BottomModal/BottomModal';
import { Tag } from '../Tag/Tag';
import './tagsParam.css';

export function TagsParam() {

    const tags = useSelector(selectTags);
    const dispatch = useDispatch();
    const [tag, setTag] = useState<TagModel | null>(null);

    useEffect(() => {
        dispatch(fetchTags());
    }, [dispatch])

    const openBottomModal = (tag: TagModel | undefined = undefined) => {
        if (tag === undefined) {
            tag = { id: -1, name: '', color: '' } as TagModel;
        }

        setTag(tag);
    }

    const closeBottomModal = () => {
        setTag(null);
    }

    const onChangeTagName = (e: any) => {
        setTag({ ...tag, name: e.currentTarget.value } as TagModel);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (tag === null) {
            return;
        }

        if (tag.id === -1) {
            dispatch(fetchAddTag(tag.name));
            closeBottomModal();
            return;
        }

        dispatch(fetchUpdateTag(tag.id, tag.name));
        closeBottomModal();
    }

    return (
        <div className="tagParams">
            {tags.map(tag => <Tag tagId={tag.id} key={tag.id} onClick={() => openBottomModal(tag)} />)}
            <button className="addButton" onClick={() => openBottomModal()}><i className="bi bi-plus"></i></button>
            {tag !== null &&
                <BottomModal
                    title={tag.id === -1 ? 'Ajouter un tag' : 'Modifier un tag'}
                    onClose={() => closeBottomModal()}
                >

                    <form onSubmit={onSubmit}>
                        <input type="text" value={tag.name} onChange={onChangeTagName} placeholder="Nom du tag" />
                        <button type="submit">Enregistrer</button>
                    </form>

                </BottomModal>
            }
        </div>
    )
}
