import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchGetSubscriptions, fetchUnFollow } from '../../app/fetchApi';
import { selectSubscriptionsAuthors, selectSubscriptionsSeries } from '../../slices/subscriptionsSlice';
import './subscriptionsParam.css';

export function SubscriptionsParam() {
    const dispatch = useDispatch();
    const series = useSelector(selectSubscriptionsSeries);
    const authors = useSelector(selectSubscriptionsAuthors);

    useEffect(() => {
        dispatch(fetchGetSubscriptions());
    }, [dispatch])
    return (
        <div className="subscriptionsParam">
            <strong>Séries</strong>
            {series.length === 0 && <div className="notFoundMessage">Tu ne suis aucune série.</div>}
            <ul>
                {series.map((subscription) =>
                    <li key={subscription.url}>
                        <Link to={`/author?url=${subscription.url}&website=booknode`}>{subscription.name}</Link>
                        <button onClick={() => dispatch(fetchUnFollow(subscription.url, 'series'))}>Ne plus suivre</button>
                    </li>
                )}
            </ul>
            <strong>Auteurs</strong>
            {authors.length === 0 && <div className="notFoundMessage">Tu ne suis aucun auteur.</div>}
            <ul>
                {authors.map((subscription) =>
                    <li key={subscription.url}>
                        <Link to={`/author?url=${subscription.url}&website=booknode`}>{subscription.name}</Link>
                        <button onClick={() => dispatch(fetchUnFollow(subscription.url, 'author'))}>Ne plus suivre</button>
                    </li>
                )}
            </ul>
        </div>
    )
}
