import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface Subscription {
    url: string,
    name: string,
}

export interface SubscriptionState {
    series: Subscription[]
    authors: Subscription[]
}

const initialState: SubscriptionState = {
    series: [],
    authors: []
};

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setSubscriptions: (state, action) => {
            state.series = action.payload.series;
            state.authors = action.payload.authors;
        },
        removeSeriesSubcription: (state, action) => {
            const url = action.payload;
            const series = state.series.filter(subscription => subscription.url !== url);
            state.series = series;
        },
        removeAuthorSubcription: (state, action) => {
            const url = action.payload;
            const authors = state.authors.filter(subscription => subscription.url !== url);
            state.authors = authors;
        },
        addSeriesSubcription: (state, action) => {
            const series = state.series.slice();
            series.push({ url: action.payload.url, name: action.payload.name });
            state.series = series;
        },
        addAuthorSubcription: (state, action) => {
            const series = state.series.slice();
            series.push({ url: action.payload.url, name: action.payload.name });
            state.series = series;
        }
    }

});

export const { setSubscriptions, removeSeriesSubcription, removeAuthorSubcription, addSeriesSubcription, addAuthorSubcription } = subscriptionsSlice.actions;

export const selectSubscriptionsSeries = (state: RootState) => state.subscriptions.series;
export const selectSubscriptionsAuthors = (state: RootState) => state.subscriptions.authors;

export default subscriptionsSlice.reducer;
