import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api, fetchInit, fetchNotifications, fetchSearch } from '../../app/fetchApi';
import { RootState } from '../../app/store';
import { getInternSearchTerm, getSearchFilter, HomeBook, selectBooksForShelf, setSearch } from '../../slices/booksSlice';
import { selectShelves, Shelf } from '../../slices/shelvesSlice';
import { BookItem } from '../../features/BookItem/BookItem';
import { BottomBooksModal } from './BottomBooksModal';
import './home.css';
import { Link, Navigate, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { selectTags } from '../../slices/tagsSlice';

export interface BooknodeSearch {
    authors: BooknodeSearchAuthor[],
    books: BooknodeSearchBook[],
    series: BooknodeSearchSeries[],
    themes: object[],
    users: object[]
}

export interface BooknodeSearchSeries {
    book_count: number,
    cover: number,
    href: string,
    id: number,
    name: string,
}

export interface BooknodeSearchBook {
    authors: BooknodeSearchBookAuthor[],
    cover_double: string,
    cover_medium: string,
    cover_quicker_search: string,
    cover_small: string,
    custom: any[],
    href: string,
    id: number,
    name: string,
    slug: string
}

export interface BooknodeSearchBookAuthor {
    href: string,
    idauteur: number,
    nom: string
}

export interface BooknodeSearchAuthor {
    book_count: number,
    books_href: string,
    cover: string,
    cover_medium: string,
    cover_quick_search: string,
    firstname: string,
    href: string,
    id: number,
    lastname: string,
    name: string,
}

interface FilterSearch {
    internSearch: boolean,
    searchInTitle: boolean,
    searchInDescription: boolean,
    searchInAuthor: boolean
}

export function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSearchActive, setSearchActive] = useState(window.location.pathname === '/search' ? true : false);

    useEffect(() => {
        if (!window.localStorage.getItem('token') || !window.localStorage.getItem('refreshToken')) {
            navigate('/connection');
            return;
        }
        if (window.location.pathname === '/') {
            if (!api.token) {
                api.setToken(window.localStorage.getItem('token'));
            }
            if (!api.refreshToken) {
                api.setRefreshToken(window.localStorage.getItem('refreshToken'));
            }
            navigate('/books');
        }
    }, []);

    const onSearch = (search: string, filter: FilterSearch) => {
        if (!search) {
            closeSearch();
            dispatch(setSearch({
                term: '',
                inTitle: true,
                inDescription: false,
                inAuthor: false
            }))
            return;
        }

        if (filter.internSearch) {
            dispatch(setSearch({
                term: search,
                inTitle: filter.searchInTitle,
                inDescription: filter.searchInDescription,
                inAuthor: filter.searchInAuthor
            }))
            return;
        }

        dispatch(setSearch({
            term: '',
            inTitle: true,
            inDescription: false,
            inAuthor: false
        }))

        navigate('/search?term=' + search);
        setSearchActive(true);
    }

    const closeSearch = () => {
        setSearchActive(false);
        navigate('/books');
    }

    return (
        <div className="homeScreen">
            <Outlet />
            <Search hasSearchPage={isSearchActive} onSearch={onSearch} closeSearch={closeSearch} />
        </div>
    );
}


interface SearchProps {
    onSearch: Function,
    hasSearchPage: boolean,
    closeSearch: Function
}

function Search(props: SearchProps) {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const internSearchTerm = useSelector(getInternSearchTerm);
    const searchFilter = useSelector(getSearchFilter);
    const defaultValue = params.getAll('term') && params.getAll('term')[0] ? params.getAll('term')[0] : internSearchTerm ? internSearchTerm : '';
    const [isSearchActive, setIsSearchActive] = useState(window.location.pathname === '/search' ? true : false);
    const searchRef: any = useRef(null);

    const [internSearch, setInternSearch] = useState(internSearchTerm ? true : false);
    const [searchInTitle, setSearchInTitle] = useState(searchFilter.inTitle);
    const [searchInDescription, setSearchInDescription] = useState(searchFilter.inDescription);
    const [searchInAuthor, setSearchInAuthor] = useState(searchFilter.inAuthor);
    const [openFilter, setOpenFilter] = useState(false);

    const toggleSearch = () => {
        if (!isSearchActive === false) {
            props.closeSearch();
        }
        setIsSearchActive(!isSearchActive);

    }
    useEffect(() => {
        if (isSearchActive && searchRef && searchRef.current) {

            searchRef.current.focus();
        }
    }, [isSearchActive])

    const filterChange = (fieldName: string, value: boolean) => {
        switch (fieldName) {
            case 'internSearch':
                setInternSearch(value);
                break;
            case 'searchInTitle':
                setSearchInTitle(value);
                break;
            case 'searchInDescription':
                setSearchInDescription(value);
                break;
            case 'searchInAuthor':
                setSearchInAuthor(value);
                break;
        }
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        setOpenFilter(false);
        props.onSearch(
            searchRef && searchRef.current ? searchRef.current.value : '',
            {
                internSearch,
                searchInTitle,
                searchInDescription,
                searchInAuthor
            }
        );
    }

    const onFilter = (e: any) => {
        e.preventDefault();
        setOpenFilter(!openFilter)
    }

    return (
        <div className={`searchContainer ` + (isSearchActive ? 'isOpen' : '')}>
            {isSearchActive && <div>
                {openFilter &&
                    <div className="searchFilter">
                        <p><strong>Filtrer</strong></p>
                        <label>
                            <input type="checkbox" checked={internSearch} onChange={(e) => filterChange('internSearch', e.currentTarget.checked)} />
                            <span>Faire la recherche dans mes livres</span>
                        </label>
                        <div className="fieldsSearch">
                            <div>
                                <strong>Rechercher dans :</strong>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" checked={searchInTitle} disabled={!internSearch} onChange={(e) => filterChange('searchInTitle', e.currentTarget.checked)} />
                                    <span>Le titre</span>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" checked={searchInDescription} disabled={!internSearch} onChange={(e) => filterChange('searchInDescription', e.currentTarget.checked)} />
                                    <span>La description</span>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" checked={searchInAuthor} disabled={!internSearch} onChange={(e) => filterChange('searchInAuthor', e.currentTarget.checked)} />
                                    <span>Le nom de l'auteur</span>
                                </label>
                            </div>
                        </div>
                        <button onClick={onSubmit}>Fermer</button>
                    </div>
                }
                {!props.hasSearchPage &&
                    <button className="booksByThemeBtn" onClick={() => navigate('/searchByhemes')}>
                        <i className="bi bi-search"></i> <span>Livres par thèmes</span>
                    </button>
                }
                <button className="bookByScanBtn" onClick={() => navigate('/scan')}>
                    <i className="bi bi-upc-scan"></i> <span>Scanner un code barre</span>
                </button>
                <form className="inputSection" onSubmit={onSubmit}>
                    <input ref={searchRef} id="searchInput" type="text" defaultValue={defaultValue} placeholder="Rechercher un livre"></input>
                    <a className="filterBtn" onClick={onFilter}><i className="bi bi-filter"></i></a>
                </form>
            </div>
            }
            <button className="searchBtn" onClick={() => toggleSearch()}>
                <i className={isSearchActive ? 'bi bi-x-lg' : 'bi bi-search'}></i>
                {internSearchTerm && <span className="internSearchActivate"></span>}
            </button>
        </div>
    )
}
