import React from 'react';
import './bookSkeleton.css';

export function BookSkeleton() {

    return (
        <div className="bookSkeleton">
            <div className="imageContainer"></div>
            <div className="body">
                <h1></h1>

                <div className="authorSection">
                    <strong></strong><br />
                    <span></span>
                </div>

                <div className="dateSection">
                    <div>
                        <strong></strong><br />
                        <span></span>
                    </div>
                    <div>
                        <strong></strong><br />
                        <span></span>
                    </div>
                    <div>
                        <strong></strong><br />
                        <span></span>
                    </div>
                </div>

                <div className="descriptionSection">
                    <strong></strong>
                    <div></div>
                </div>

                <div className="linkSection"></div>

                <div className="themeSection">
                    <strong></strong>
                    <div></div>
                </div>

            </div>
        </div>
    );
}
