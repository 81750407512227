import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import booksReducer from '../slices/booksSlice';
import shelvesReducer from '../slices/shelvesSlice';
import tagsReducer from '../slices/tagsSlice';
import UserReducer from '../slices/userSlice';
import SubscriptionsReducer from '../slices/subscriptionsSlice';

export const store = configureStore({
  reducer: {
    books: booksReducer,
    shelves: shelvesReducer,
    tags: tagsReducer,
    user: UserReducer,
    subscriptions: SubscriptionsReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
