import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInit } from '../../app/fetchApi';
import { RootState } from '../../app/store';
import { getSearchTags, HomeBook, selectFilterBooksForShelf, setTagsFilter } from '../../slices/booksSlice';
import { selectShelves, Shelf } from '../../slices/shelvesSlice';
import { BookItem } from '../../features/BookItem/BookItem';
import { BottomBooksModal } from './BottomBooksModal';
import './home.css';
import { Link } from 'react-router-dom';
import { selectTags } from '../../slices/tagsSlice';
import { selectNbAddingMoreBooks, selectNbBooksByShef } from '../../slices/userSlice';
import { HomeBookSkeleton } from './HomeBookSkeleton';

export function HomeBooks() {
    const dispatch = useDispatch();
    const tagsFilter = useSelector(getSearchTags);
    const [nbNewsNotif, setNbNewsNotif] = useState(0);

    const toggleTagFilter = (tagId: number) => {
        let newTagsFilter = tagsFilter.slice();

        if (tagsFilter.indexOf(tagId) === -1) {
            newTagsFilter.push(tagId);
        } else {
            newTagsFilter = tagsFilter.filter(oldTagId => oldTagId !== tagId);
        }

        dispatch(setTagsFilter(newTagsFilter));

    }

    return (
        <>
            <header>
                <h1>Mes étagères</h1>
                <div>
                    <Link to="/notifications"><i className={"bi bi-bell-fill " + (nbNewsNotif ? 'isNew' : '')}></i>{nbNewsNotif > 0 && <span className="isNewIcon">{nbNewsNotif}</span>}</Link>
                    <Link to="/stats"><i className="bi bi-pie-chart"></i></Link>
                    <Link to="/param"><i className="bi bi-gear-wide-connected"></i></Link>
                </div>
            </header>
            <main>
                <Books setNbNewsNotif={(nbNews: number) => setNbNewsNotif(nbNews)} />
                <TagsFilter toggle={toggleTagFilter} tagsFilter={tagsFilter} />
            </main>
        </>
    );
}
interface TagsFilterProps {
    toggle: Function,
    tagsFilter: number[]
}
function TagsFilter(props: TagsFilterProps) {
    const tags = useSelector(selectTags);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`tagsFilter ` + (isOpen ? 'open' : '')}>
            <div>
                {tags.map(tag =>
                    <div
                        key={tag.id}
                        className="tag"
                        style={{ borderColor: tag.color, color: props.tagsFilter.indexOf(tag.id) === -1 ? tag.color : '#fff', backgroundColor: props.tagsFilter.indexOf(tag.id) === -1 ? '#fff' : tag.color }}
                        onClick={() => props.toggle(tag.id)}
                    >
                        <i className="bi bi-tag-fill"></i>{tag.name}
                    </div>
                )}
            </div>
            <button className="openTagFilter" onClick={() => setIsOpen(!isOpen)}><i className="bi bi-tags-fill"></i><span>{props.tagsFilter.length > 0 ? `(${props.tagsFilter.length})` : ''}</span></button>
        </div>
    )
}
interface BooksProps {
    setNbNewsNotif: Function
}
function Books(props: BooksProps) {
    const shelves = useSelector(selectShelves);
    const [init, setInit] = useState(false);

    const dispatch = useDispatch();

    const displayShelfLocalStorage: any = localStorage.getItem('displayShelf') !== null ? localStorage.getItem('displayShelf') : 'ALL';
    const initDisplayShelf: number | 'ALL' = displayShelfLocalStorage !== 'ALL' ? parseInt(displayShelfLocalStorage) : displayShelfLocalStorage;
    const [displayShelfId, setDisplayShelfId] = useState<number | 'ALL'>(initDisplayShelf);

    useEffect(() => {
        setInit(true);
        dispatch(fetchInit((nbNews: number) => props.setNbNewsNotif(nbNews)));
    }, [dispatch])

    useEffect(() => {
        if (init) {
            setInit(false);
        }
    }, [shelves])

    const changeSelectedShelf = (shelfId: number | 'ALL') => {
        setDisplayShelfId(shelfId);
        localStorage.setItem('displayShelf', shelfId !== 'ALL' ? shelfId.toString() : 'ALL');
    }

    if (init) {
        return <HomeBookSkeleton />;
    }

    return (
        <>
            <ul className="menu-shelves horizontalScroll">
                <li className={displayShelfId === 'ALL' ? 'selected' : ''} onClick={() => changeSelectedShelf('ALL')} ><a>TOUT VOIR</a></li>
                {shelves.map((shelf: Shelf) => <li key={shelf.id} className={displayShelfId === shelf.id ? 'selected' : ''} onClick={() => changeSelectedShelf(shelf.id)} ><a>{shelf.name}</a></li>)}
            </ul>
            <div className="booksShelves scroll">
                {shelves.map((shelf: Shelf) => {
                    if (displayShelfId !== 'ALL' && displayShelfId !== shelf.id) {
                        return;
                    }

                    return (
                        <div key={shelf.id} className="shelf">
                            {displayShelfId === 'ALL' && <ShelfName name={shelf.name} nbBooks={shelf.nbBooks} />}
                            <BooksByShelf shelfId={shelf.id} />
                        </div>
                    )
                })}
            </div>
        </>
    )
}
interface ShelfNameProps {
    name: string,
    nbBooks: number
}
const ShelfName = React.memo((props: ShelfNameProps) => {
    return (
        <strong>{props.name} ({props.nbBooks})</strong>
    );
})
interface BooksByShelfProps {
    shelfId: number
}

export function BooksByShelf(props: BooksByShelfProps) {
    const dispatch = useDispatch();
    const filtersBooks = useSelector((state: RootState) => selectFilterBooksForShelf(state, props.shelfId));
    const nbBooksByShef = useSelector(selectNbBooksByShef);
    const nbAddingMoreBooks = useSelector(selectNbAddingMoreBooks);
    const initBookToShow: any = {};
    const [bookToShow, setBookToShow] = useState(initBookToShow);

    const [tempBooks, setTempBooks] = useState<HomeBook[]>([]);
    const [startSlice, setStartSlice] = useState(0);

    useEffect(() => {
        initTmpBooks();
    }, [filtersBooks, dispatch])

    const initTmpBooks = () => {
        if (filtersBooks.length === 0 && tempBooks.length === 0) {
            return;
        }

        if (nbBooksByShef === 0 || filtersBooks.length <= nbBooksByShef) {
            setTempBooks(filtersBooks.slice());
            return;
        }

        setTempBooks(filtersBooks.slice(0, nbBooksByShef));
        setStartSlice(nbBooksByShef);
    }


    const REMOVING_AFTER_LENTGH = nbBooksByShef + (nbAddingMoreBooks * 2);
    const nextTmpBooks = () => {

        const nextStartSlice = startSlice + nbAddingMoreBooks;
        let toAdd = filtersBooks.slice(startSlice, nextStartSlice);
        let newTempBooks = [...tempBooks, ...toAdd];

        //on supprime les livres du début
        if (newTempBooks.length >= REMOVING_AFTER_LENTGH) {
            newTempBooks = newTempBooks.slice(nbAddingMoreBooks, newTempBooks.length);
        }

        setTempBooks(newTempBooks);
        setStartSlice(nextStartSlice);
    }

    const prevTmpBooks = () => {
        let nextStartSlice = startSlice;
        let newTempBooks = filtersBooks.slice(startSlice - tempBooks.length - nbAddingMoreBooks, nextStartSlice);

        //on supprime les livres de la fin
        if (newTempBooks.length >= REMOVING_AFTER_LENTGH) {
            nextStartSlice = newTempBooks.length - nbAddingMoreBooks;
            newTempBooks = newTempBooks.slice(0, newTempBooks.length - nbAddingMoreBooks);
        }

        setTempBooks(newTempBooks);
        setStartSlice(nextStartSlice);
    }

    return (
        <>
            {startSlice > 0 && startSlice >= REMOVING_AFTER_LENTGH && startSlice - tempBooks.length > 0 && tempBooks.length !== filtersBooks.length && <button className="seePrevBooks" onClick={() => prevTmpBooks()}>Afficher les précédents livres</button>}

            <div className="booksList">
                {filtersBooks.length === 0 && <div className="notFoundMessage">Vous n'avez aucun livre dans cette étagère.</div>}
                {tempBooks.map((book: HomeBook) => {
                    return <BookItem
                        key={book.shelfId + '_' + book.seriesUrl}
                        name={book.name}
                        seriesName={book.seriesName}
                        image={book.image}
                        nbBooks={book.nbBooks}
                        hasComment={book.isOneBookHasComment}
                        hasTags={book.tags.length > 0}
                        isComingSoon={book.isOneBookIsComingSoon}
                        width="100%"

                        onClick={() => setBookToShow({
                            title: book.seriesName ? book.seriesName : book.name,
                            booksId: book.booksId
                        })}
                    />
                })}
                {Object.keys(bookToShow).length !== 0 && <BottomBooksModal
                    title={bookToShow.title}
                    booksId={bookToShow.booksId}
                    onClose={() => setBookToShow(initBookToShow)}
                />}
            </div>
            {startSlice > 0 && startSlice <= filtersBooks.length && <button className="seeNextBooks" onClick={() => nextTmpBooks()}>Afficher les prochains livres</button>}
        </>

    );
}
