import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchHistory, fetchHistoryRemove } from '../../app/fetchApi';
import './history.css';

export interface History {
    id: number,
    content: string,
    link: string,
    linkType: 'FORM_BOOK' | 'FORM_AUTHOR'
}

export function History() {
    const dispatch = useDispatch();
    const [history, setHistory] = useState<History[]>([]);
    useEffect(() => {
        dispatch(fetchHistory((history: History[]) => setHistory(history)));
    }, [dispatch])


    const remove = (id: number | 'ALL') => {
        dispatch(fetchHistoryRemove(id, () => {
            let newHistory: History[] = [];

            if (id !== 'ALL') {
                newHistory = history.filter((historyLine) => historyLine.id !== id);
            }

            setHistory(newHistory);
        }))
    }

    return <div className="historyParam">
        <div className="empty">{history.length > 0 && <a onClick={() => remove('ALL')}>tout vider</a>}</div>
        {history.length === 0 && <div className="notFoundMessage">Aucun historique à afficher.</div>}
        <ul>
            {history.map(historyLine =>
                <li key={historyLine.id}>
                    {historyLine.linkType === 'FORM_AUTHOR' ? <i className="bi bi-person-circle"></i> : <i className="bi bi-book"></i>}
                    <Link to={historyLine.linkType === 'FORM_AUTHOR' ? `/author?url=${historyLine.link}&website=booknode` : `/book?url=${historyLine.link}&website=booknode`}>{historyLine.content}</Link>
                    <button className="close" onClick={() => remove(historyLine.id)}><i className="bi bi-x-lg"></i></button>
                </li>
            )}
        </ul>
    </div>;
}
