import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './hello.css';

export default function Hello() {
    const navigate = useNavigate();

    return (
        <div className="hello">
            <h1>Hello !</h1>
            <p>Besoin d'une aide pour organiser ta lecture ? D'être notifier sur les prochaines sorties ?</p>
            <p>Alors cette appli est faites pour toi !</p>
            <footer>
                <button onClick={() => navigate('/explanation')}>Commencer l'aide</button>
                <div><a onClick={() => navigate('/books')}>Ignorer l'aide</a></div>
            </footer>
        </div>
    )

}
