import React from 'react';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { Book } from './screens/Book/Book';
import { Home } from './screens/Home/Home';
import { SystemMessage } from './features/SystemMessage/SystemMessage';
import { Param } from './screens/Param/Param';
import { HomeSearch } from './screens/Home/SearchBooks';
import { HomeBooks } from './screens/Home/HomeBooks';
import { Author } from './screens/Author/Author';
import { TagsParam } from './features/TagsParam/TagsParam';
import { SubscriptionsParam } from './features/SubscriptionsParam/SubscriptionsParam';
import { History } from './features/History/History';
import { Notification } from './screens/Notification/Notification';
import { ShelvesParam } from './features/ShelvesParam/ShelvesParam';
import { BookComments } from './screens/BookComments/BookComments';
import { BookExtracts } from './screens/BookExtracts/BookExtracts';
import { SearchByTheme } from './screens/SearchByTheme/SearchByTheme';
import { Connection } from './screens/Connection/Connection';
import { ErrorMessage } from './features/ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import { selectTheme } from './slices/userSlice';
import Explanation from './features/Explanation/Explanation';
import Hello from './features/Hello/Hello';
import { Scan } from './screens/Scan/Scan';
import { Stats } from './screens/Stats/Stats';
window.addEventListener('beforeinstallprompt', (e: any) => {
    e.preventDefault();
    setTimeout(() => e.prompt(), 10000);
});

function App() {
    const theme = useSelector(selectTheme);
    return (
        <div className={'theme ' + (theme ? theme : 'basic')}>
            <BrowserRouter>
                <Routes>
                    <Route path="/connection" element={<Connection />} />
                    <Route path="/" element={<Home />} >
                        <Route path="/books" element={<HomeBooks />} />
                        <Route path="/search" element={<HomeSearch />} />
                    </Route>
                    <Route path="/scan" element={<Scan />} />
                    <Route path="/hello" element={<Hello />} />
                    <Route path="/explanation" element={<Explanation />} />
                    <Route path="/stats" element={<Stats />} />
                    <Route path="/param" element={<Param />} >
                        <Route path="/param/tags" element={<TagsParam />} />
                        <Route path="/param/subscriptions" element={<SubscriptionsParam />} />
                        <Route path="/param/history" element={<History />} />
                        <Route path="/param/shelves" element={<ShelvesParam />} />
                        <Route path="/param/explanation" element={<Explanation />} />
                    </Route>
                    <Route path="/notifications" element={<Notification />} />
                    <Route path="/author" element={<Author />} />
                    <Route path="/book" element={<Book />} />
                    <Route path="/bookComments" element={<BookComments />} />
                    <Route path="/bookExtracts" element={<BookExtracts />} />
                    <Route path="/searchByhemes" element={<SearchByTheme />} />
                </Routes>
            </BrowserRouter>
            <SystemMessage />
            <ErrorMessage />
        </div>
    );
}

export default App;
