import React, { MouseEventHandler, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchChangeComment } from '../../app/fetchApi';
import { BottomModal } from '../BottomModal/BottomModal';
import './bookCommentModal.css';

interface BookCommentModalProps {
    bookId: number,
    comment: string,
    onClose: Function,
    callback: Function
}
export function BookCommentModal(props: BookCommentModalProps) {

    const dispatch = useDispatch();
    const commentRef = useRef(null);

    const send = () => {
        const commentElement: any = commentRef.current;
        dispatch(fetchChangeComment(props.bookId, commentElement !== null ? commentElement.value : props.comment, props.callback));
        props.onClose();
    }

    return (
        <BottomModal
            title={'Mon commentaire'}
            onClose={() => props.onClose()}
        >
            <div className="commentModal">
                <textarea ref={commentRef} placeholder="Ajouter un commentaire" defaultValue={props.comment} />
                <button onClick={() => send()}>Enregistrer</button>
            </div>
        </BottomModal>
    )
}
