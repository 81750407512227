import React, { MouseEventHandler } from 'react';
import './bottomModal.css';

interface BottomModalProps {
    title: string | React.ReactNode,
    subTitle?: string | React.ReactNode,
    onClose: MouseEventHandler
}

export function BottomModal(props: React.PropsWithChildren<BottomModalProps>) {

    return (
        <>
            <div className="backgroundModal" onClick={props.onClose}></div>
            <div className="bottomModal">
                <button className="removeBtn" onClick={props.onClose}>
                    <i className="bi bi-x-circle-fill"></i>
                </button>
                <div className="bottomModal-title">
                    <strong>{props.title}</strong>
                    {props.subTitle && <div className="subTitle">{props.subTitle}</div>}
                </div>
                <div className="bottomModal-body">
                    {props.children}
                </div>
            </div>
        </>
    )

}
