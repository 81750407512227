import { objectSerialize, generateUniqueId } from '../utils/Utils';
export const host = 'https://booknode.com';

class BooknodeApi {

    constructor() {}

    errorFunction = () => {}

    setErrorFunction = (errorFunction) => {
        this.errorFunction = errorFunction;
    }

    headers() {
        return {}
    }

    search(word, callback) {
        return this.xhr('search-json', null, { q : word, exclude_series_from_books: 0 }, callback, 'GET');
    }

    xhr(route, params, query, callback, verb) {
        const queryString = query ? '?' + objectSerialize(query) : '';
        const url = `${host}/${route}${queryString}`;
        let options = Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : {});
        options.headers = this.headers();
        const self = this;
        // console.log('FETCH_INFO - START URL : ', url, options);

        fetch(url, options)
            .then(response => {
                // console.log('FETCH_INFO - END URL : ', url, ' STATUS : ', response.status);
                if (response.status === 200 && typeof callback !== 'undefined') {
                    return response.json();
                }

                if (response.status !== 200) {
                    throw 'ERROR DATABASE - ' + response.status + ' - URL : ' + url;
                }
            }).then((response) => {
                callback(response);
            }).catch((err)=> {
                console.log('DB_ERROR', err, url);
                self.errorFunction(generateUniqueId(), typeof err === 'object' ? JSON.stringify(err) : err);
                return;
            });

    }

}
export default BooknodeApi;
