import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BOOKNODE_URL, fetchSearch } from '../../app/fetchApi';
import './home.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { BooknodeSearch, BooknodeSearchAuthor, BooknodeSearchBook } from './Home';
import SearchBooksSkeleton from './SearchBooksSkeleton';

export function HomeSearch() {

    const [params] = useSearchParams();
    const term = params.getAll('term') && params.getAll('term')[0] ? params.getAll('term')[0] : '';
    const dispatch = useDispatch();
    const [searchItems, setSearchItems] = useState<null | BooknodeSearch>(null);
    const [waitingSearch, setWaitingSearch] = useState(false);
    useEffect(() => {
        setWaitingSearch(true);
        fetchSearch(term, 'booknode', (result: BooknodeSearch) => {
            setWaitingSearch(false);
            setSearchItems(result);
        }, dispatch);

    }, [term])

    return (
        <>
            <header>
                <h1>Recherche</h1>
                <div>
                    <Link to="/param"><i className="bi bi-gear-wide-connected"></i></Link>
                </div>
            </header>
            <main>
                {waitingSearch && <SearchBooksSkeleton />}
                {searchItems !== null && !waitingSearch && <SearchBooks authors={searchItems.authors} books={searchItems.books} />}
            </main>
        </>
    );
}

interface SearchBooksProps {
    authors: BooknodeSearchAuthor[],
    books: BooknodeSearchBook[]
}

function SearchBooks(props: SearchBooksProps) {
    const [showType, setShowType] = useState<'book' | 'author'>('book');
    const navigate = useNavigate();

    return (
        <>
            <div className="searchMenu">
                <strong onClick={() => setShowType('book')} className={showType === 'book' ? 'isSelected' : ''}>Livres</strong> | <strong onClick={() => setShowType('author')} className={showType === 'author' ? 'isSelected' : ''}>Auteurs</strong>
            </div>
            <div className="searchList">
                {showType === 'book' && props.books.map((book) =>
                    <div key={book.id} className="searchBook">
                        <div onClick={() => navigate('/book?url=' + book.href.replace(BOOKNODE_URL, '') + '&website=booknode')} className="imageSearchBook" style={{ backgroundImage: 'url(' + book.cover_small + ')' }}></div>
                        <div>
                            <strong onClick={() => navigate('/book?url=' + book.href.replace(BOOKNODE_URL, '') + '&website=booknode')}>{book.name}</strong>
                            {book.authors && typeof book.authors[0] !== 'undefined' && <a className="link" onClick={() => navigate('/author?url=' + book.authors[0].href + '&website=booknode')}>{book.authors[0].nom}</a>}
                        </div>
                    </div>
                )}
                {showType === 'author' && props.authors.map((author) =>
                    <div key={author.id} className="searchAuthor" onClick={() => navigate('/author?url=' + author.href.replace(BOOKNODE_URL, '') + '&website=booknode')}>
                        <div className="imageSearchAuthor" style={{ backgroundImage: 'url(' + author.cover_medium + ')' }}></div>
                        <span>{author.name}</span>
                    </div>
                )}

            </div>
        </>
    )
}
