import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import Api from '../app/api';
import { RootState, AppThunk } from '../app/store';

export interface Shelf {
    id: number,
    name: string,
    order: number,
    over: boolean,
    nbBooks: number

}

export interface ShelvesState {
    shelves: Shelf[]
}

const initialState: ShelvesState = {
    shelves: []
};


export const shelvesSlice = createSlice({
    name: 'shelves',
    initialState,
    reducers: {
        setShelves: (state, action) => {
            state.shelves = action.payload;
        },


    }

});

export const { setShelves } = shelvesSlice.actions;

export const selectShelves = (state: RootState) => state.shelves.shelves;

export default shelvesSlice.reducer;
