import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './shelvesParam.css';
import { selectShelves, Shelf } from '../../slices/shelvesSlice';
import { fetchShelves, fetchUpdateShelves } from '../../app/fetchApi';
import { BottomModal } from '../BottomModal/BottomModal';
import { setSystemMessage } from '../../slices/userSlice';


export function ShelvesParam() {
    const dispatch = useDispatch();
    const defaultShelves = useSelector(selectShelves);
    const [items, setItems] = useState<any[]>([]);
    const [shelfToRemove, setShelfToRemove] = useState<any>(null);
    const [newIdShelf, setNewIdShelf] = useState<any>(-1);
    const shelvesRef = useRef<any>(null);

    useEffect(() => {
        dispatch(fetchShelves());
    }, [dispatch])

    useEffect(() => {
        let newItems: any[] = [];
        let shelves = defaultShelves.slice();
        shelves.sort((first: any, second: any) => first.order - second.order);
        shelves.map(shelf => newItems.push({ id: shelf.id, name: shelf.name, nbBooks: shelf.nbBooks, over: shelf.over }));
        setItems(newItems);
    }, [defaultShelves])

    const addShelf = () => {
        const newItems = items.slice();
        newItems.push({ id: newIdShelf, name: '', nbBooks: 0 });
        setNewIdShelf(newIdShelf - 1);
        setItems(newItems);
    }

    const removeShelf = (shelfIndex: number, nbBooks: number) => {
        const newItems = items.slice();
        const shelf = newItems[shelfIndex];
        if (nbBooks > 0 || (typeof shelf.replaceShelf !== 'undefined' && shelf.replaceShelf.length > 0)) {
            setShelfToRemove(shelf);
            return;
        }
        newItems.splice(shelfIndex, 1);
        setItems(newItems);
    }

    const changeShelfName = (shelfIndex: number, name: string) => {
        const newItems = items.slice();
        newItems[shelfIndex].name = name;
        setItems(newItems);
    }

    const changeShelfOrder = (shelfIndex: number, newIndex: number) => {
        if (newIndex < 0 || newIndex > items.length + 1) {
            return;
        }
        const newItems = items.slice();
        const item = newItems.splice(shelfIndex, 1);
        newItems.splice(newIndex, 0, ...item);
        setItems(newItems);
    }

    const saveShelf = () => {
        const newShelves: any[] = [];
        const newItems = items.slice();
        newItems.map((item, index) => newShelves.push({ id: item.id, name: item.name, order: index + 1, over: item.over, replaceShelf: item.replaceShelf }));
        dispatch(fetchUpdateShelves(newShelves, () => { }));
    }

    const closeBottomModal = () => {
        setShelfToRemove(null);
    }

    const changeShelfForBooks = () => {
        if (shelvesRef && shelvesRef.current) {
            const newShelf = items.find(item => item.id === parseInt(shelvesRef.current.value));
            if (!newShelf) {
                closeBottomModal();
                return;
            }

            if (typeof newShelf.replaceShelf === 'undefined') {
                newShelf.replaceShelf = [];
            }

            newShelf.replaceShelf.push(shelfToRemove.id);
            if (typeof shelfToRemove.replaceShelf !== 'undefined') {
                newShelf.replaceShelf.splice(0, 0, ...shelfToRemove.replaceShelf);
                shelfToRemove.replaceShelf = [];
            }

        }

        removeShelf(items.findIndex(item => item.id === shelfToRemove.id), 0);
        closeBottomModal();
    }

    const setOverState = (index: number, newOverState: boolean) => {
        const newItems = items.slice();
        newItems[index].over = newOverState;
        setItems(newItems);
    }

    return <div className="shelvesParam">
        <button className="saveButton" onClick={() => saveShelf()}><i className="bi bi-check"></i></button>
        <button className="addButton" onClick={() => addShelf()}><i className="bi bi-plus"></i></button>

        <div className="shelvesList">
            {items.map((shelf, index) =>
                <div className="shelf" key={index}>
                    <button className="arrowDirection" onClick={() => changeShelfOrder(index, index - 1)}><i className="bi bi-arrow-up"></i></button>
                    <button className="arrowDirection" onClick={() => changeShelfOrder(index, index + 1)}><i className="bi bi-arrow-down"></i></button>

                    <input type="text" value={shelf.name} onChange={(e) => changeShelfName(index, e.currentTarget.value)} />
                    <button className="overShelfButton" onClick={() => setOverState(index, !shelf.over)}><i className={shelf.over ? "bi bi-bookmark-check-fill" : "bi bi-bookmark-check"}></i></button>
                    <button className="deleteButton" onClick={() => removeShelf(index, shelf.nbBooks)}><i className="bi bi-trash-fill"></i></button>
                </div>
            )}
        </div>

        {shelfToRemove !== null &&
            <BottomModal
                title={'Déplacement des livres'}
                onClose={() => closeBottomModal()}
            >
                <div className="changeShelfModal">
                    <p>Avant de supprimer l'étagère <strong>"{shelfToRemove.name}"</strong> vous devez déplacer ces livres dans une autre étagère.</p>
                    <strong>Choisir une nouvelle étagère : </strong>
                    <select ref={shelvesRef}>
                        {items.map(item => item.id !== shelfToRemove.id && (<option key={item.id} value={item.id}>{item.name}</option>))}
                    </select>
                    <button onClick={() => changeShelfForBooks()}>Valider</button>
                </div>
            </BottomModal>
        }
    </div>
}
